import React from 'react';
import styled from 'styled-components';
import NimbleLogo from '../../../static/images/Nimble-logo-red.svg'

const NavWrapper = styled.div`
  min-height: 88px;

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    min-height: 72px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    min-height: 57px;
  }
`;

const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  padding: 0px 60px;
  max-width: 1403px;
  box-sizing: border-box;
  margin: 0 auto;

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    padding: 0px 30px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    flex-wrap: wrap;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    padding: 0px 24px;
  }
`;

const LogoContainer = styled.a`
  flex: 0;

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    position: relative;
    z-index: 1200;
  }
`;

const Logo = styled.img`
  display: block;
  width: 108px;
  margin: 26px 0;

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    width: 90px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    margin: 26px 0 20px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    width: 70px;
    margin: 20px 0;
  }
`;

const HeaderNoNav = () => {
  return (
    <NavWrapper>
      <NavContainer>
        <LogoContainer href="/">
          <Logo src={NimbleLogo} alt="Nimble Logo" />
        </LogoContainer>
      </NavContainer>
    </NavWrapper>
  );
};


export default HeaderNoNav;
