import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import CardContent from './cardContent';

const CardContainer = styled.div`
  padding: 4px 12px;
  height: 100%;

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 4px 10px;
  }
`;

const LinkCard = styled.a`
  display: block;
  background-color: #ffffff;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  height: 100%;
  transition: 0.35s ease;
  transform: perspective(500px) translateZ(0px);


  &:hover{ 
    color: ${props => props.theme.colours.black};
  }

  @media (hover:hover) {
    &:hover{ 
      box-shadow: 0px 3px 8px 1px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
      transform: perspective(500px) translateZ(20px);
    }
  }

  ${props => props.columns === 2 && css`
    @media (hover:hover) and (min-width: ${props.theme.breakpoints.min.sm}) {
      &:hover{
        transform: perspective(400px) translateZ(10px);
      }
    }
  `}
`;

const Card = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  height: 100%;
`;

export default function CardsCard({
  columns,
  heading,
  shortDescription,
  longDescription,
  link,
  displayButton,
  image,
  imageAs,
  iconBackground
}) {
  return (
    <CardContainer>
      {link && link.linkUrl ? (
        <LinkCard
          columns={columns}
          href={link.linkUrl}
          target={link.openNewTab ? '_blank' : '_self'}
          className="cards-card-background"
        >
          <CardContent
            columns={columns}
            heading={heading}
            shortDescription={shortDescription}
            longDescription={longDescription}
            image={image}
            imageAs={imageAs}
            iconBackground={iconBackground}
            linkText={link.linkText}
            displayButton={displayButton}
          />
        </LinkCard>
      ) : (
        <Card className="cards-card-background">
          <CardContent
            columns={columns}
            heading={heading}
            shortDescription={shortDescription}
            longDescription={longDescription}
            image={image}
            imageAs={imageAs}
            iconBackground={iconBackground}
          />
        </Card>
      )}
    </CardContainer>
  );
}

CardsCard.propTypes = {
  columns: PropTypes.number,
  heading: PropTypes.string,
  shortDescription: PropTypes.string,
  longDescription: PropTypes.objectOf(PropTypes.string),
  link: PropTypes.shape({
    linkUrl: PropTypes.string,
    linkText: PropTypes.string,
    openNewTab: PropTypes.bool
  }),
  displayButton: PropTypes.boolean,
  image: PropTypes.string,
  imageAs: PropTypes.string,
  iconBackground: PropTypes.string
};
