import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const DropdownMenuLinksContainer = styled.div`
  max-height: 0px;
  opacity: 0;
  overflow: hidden;
  transition: 0.35s ease, opacity 0.25s ease-in;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 20;
  width: 100%;
  background-color: #ffffff;
  border-top: 0 solid #ffffff;
  margin-top: 59px;
  padding: 0;

  @media(max-width: ${props => props.theme.breakpoints.max.xl}) {
    top: -6px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
    top: 0px;
    max-height: none;
    height: 100vh;
    margin-top: 0;
    transform: translateX(100%);
    opacity: 1;
  }

  @media(hover: hover) and (min-width: ${props => props.theme.breakpoints.min.lg}) {
    &.hover:hover {
      border-top-width: 16px;
      opacity: 1;
      max-height: 500px;
      box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15);
      transition: 0.75s ease;

      & > ul {
        transition: border-top 0.2s 0.2s ease;
        border-top-color: #e8e5e5;
      }
    }
  }

  ${props => props.isCurrentExpanded && css`
      border-top-width: 16px;
      opacity: 1;
      max-height: 500px;
      box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15);
      transition: 0.75s ease;

      & > ul {
        transition: border-top 0.2s 0.2s ease;
        border-top-color: #e8e5e5;
      }

      @media(max-width: ${props.theme.breakpoints.max.lg}) {
        transform: translateX(0%);
        border-top-width: 0;
        box-shadow: unset;
        transition: 0.75s ease;
      }
  `}



  ${props => props.usedIn === 'main' && css`
    @media(max-width: ${props.theme.breakpoints.max.lg}) {
      padding: 50px 0 0;
    }

    @media(max-width: ${props.theme.breakpoints.max.sm}) {
      padding: 90px 0 0;
    }

    @media(max-width: ${props.theme.breakpoints.max.xs}) {
      padding: 75px 0 0;
    }
  `}

  ${props => props.usedIn === 'login' && css`
    z-index: -1;

    @media(max-width: ${props.theme.breakpoints.max.lg}) {
      height: auto;
      opacity: 0;
      max-height: 0px;
      transform: none;
      top: 76px;
      text-align: center;

      ${props.isCurrentExpanded && css`
        max-height: 500px;
        opacity: 1;
        box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15);
      `}
    }

    @media(max-width: ${props.theme.breakpoints.max.sm}) {
      top: 63px;
    }

    @media(max-width: ${props.theme.breakpoints.max.xs}) {
      top: 58px;
    }
  `}
`;

const DropdownMenuToggle = styled.button`
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: ${props => props.theme.font.nimbleFont};
  color: ${props => props.theme.colours.slate};
  margin-bottom: 0px;
  padding: 20px 10px;
  text-align: left;
  background-color: transparent;
  border: 0;
  transition: color 0.35s ease;
  cursor: pointer;

  &::after {
    width: 0;
    height: 0;
    transition: 0.35s ease;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.xl}) and (min-width: ${props => props.theme.breakpoints.min.lg}) {
    font-size: 14px;
    line-height: 20px;
  }

  @media(hover: hover) {
    @media(min-width: ${props => props.theme.breakpoints.min.lg})  {
      &:hover {
        &::after {
          width: 100%;
          border-bottom-color: ${props => props.theme.colours.nimbleRed};
        }
      }
    }

    @media(max-width: ${props => props.theme.breakpoints.max.lg})  {
      &:hover {
        color: ${props => props.theme.colours.darkGreen};
      }
    }
    &.hover:hover {
      & + ${DropdownMenuLinksContainer} {
        border-top-width: 16px;
        opacity: 1;
        max-height: 500px;
        box-shadow: 0px 3px 4px 1px #00000017;
        transition: 0.75s ease;

        & > ul {
          transition: border-top 0.2s 0.2s ease;
          border-top-color: #e8e5e5;
        }
      }
    }
  }

  ${props => props.usedIn === 'main' && css`
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      z-index: 21;
      border-bottom: 2px solid transparent;
    }

    @media(max-width: ${props.theme.breakpoints.max.lg}) {
      width: 100%;
      font-size: 20px;
      padding: 20px 0;
    
      &::after { 
        content: '';
        z-index: 1;
        top: 50%;
        left: auto;
        right: 0px;
        bottom: auto;
        display: inline-block;
        margin-right: 5px;
        border-right: 2px solid ${props.theme.colours.slate};
        border-top: 2px solid ${props.theme.colours.slate};
        width: 7px;
        height: 7px;
        transform: rotate(45deg) translateY(-50%);
      }
    }

    @media(max-width: ${props.theme.breakpoints.max.sm}) {
      width: 100%;
      font-size: 20px;
      padding: 0;
      margin: 30px 0;
    }

    @media(hover: hover) and (min-width: ${props.theme.breakpoints.min.lg})  {
      &:hover {
        &::after {
          width: 100%;
          border-bottom-color: ${props.theme.colours.nimbleRed};
        }
      }
    }

    ${props.isCurrentExpanded && css`
      &::after {
        width: 100%;
        border-bottom-color: ${props.theme.colours.nimbleRed};
        @media(max-width: ${props.theme.breakpoints.max.lg}) {
          width: 7px;
          border-bottom-color: transparent;
        }
      }
    `}
  `}

  /* login menu styles */
  ${props => props.usedIn === 'login' && css`
    padding: 9px 40px;
    margin: 0 12px;
    font-weight: 600;
    border: 2px solid ${props.theme.colours.neutralText};
    border-radius: 4px;

    @media (max-width: ${props.theme.breakpoints.max.xl}) {
      padding: 9px 30px;
      margin: 0 8px;
    }

    @media(max-width: ${props.theme.breakpoints.max.sm}) {
      border: 0;
      font-weight: 400;
      padding: 10px 12px 0;
    }

    @media(max-width: ${props.theme.breakpoints.max.xs}) {
      padding: 2px 12px;
    }

    .login-icon-path{
      fill: ${props.theme.colours.slate};
      transition: 0.35s ease;
    }

    @media(hover: hover) {
      &:hover{
        border-color: ${props.theme.colours.darkGreen};

        .login-icon-path{
          fill: ${props.theme.colours.darkGreen};
        }
      }
    }

    ${props.isCurrentExpanded && css`
      border-color: ${props.theme.colours.darkGreen};
      color: ${props.theme.colours.darkGreen};

      .login-icon-path{
        fill: ${props.theme.colours.darkGreen};
      }
    `}
  `}
`;

const DropdownMenuLinks = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "parent . . ."
                       "parent . . .";
  grid-gap: 35px 30px;
  border-top: 1px solid transparent;
  padding: 40px 60px;
  max-width: 1403px;
  box-sizing: border-box;
  margin: 0 auto;
  transition: border-top 0.1s ease;

  @media(max-width: ${props => props.theme.breakpoints.max.xl}) {
    grid-gap: 20px 30px;
    padding: 20px 30px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
    display: flex;
    flex-direction: column;
    border-top: none;
    height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
  }

  ${props => props.usedIn === 'login' && css`
    @media(max-width: ${props.theme.breakpoints.max.lg}) {
      padding: 20px 40px;
    }
    @media(max-width: ${props.theme.breakpoints.max.sm}) {
      padding: 20px 24px 40px;
    }
  `};

  ${props => props.usedIn === 'main' && css`
    @media(max-width: ${props.theme.breakpoints.max.lg}) {
      padding: 0 40px 20px;
    }
    @media(max-width: ${props.theme.breakpoints.max.sm}) {
      padding: 0 30px 20px;
    }

    @media(max-width: ${props.theme.breakpoints.max.xs}) {
      padding: 0 24px 20px;
    }
  `};
`;

const StyledLi = styled.li`
  margin: 0;
  padding: 0;
`;

const DropdownLink = styled.li`
  margin: 0;
  padding: 0;

  a{
    color: ${props => props.theme.colours.slate};
    transition: 0.35s ease;

    p {
      transition: 0.35s ease;
    }

    &:hover {
      color: ${props => props.theme.colours.darkGreen};

      p{ 
        color: ${props => props.theme.colours.darkGreen};
      }
    }
  }

  ${props => (props.usedIn === 'main' || props.usedIn === 'login') && props.parent && css`
    grid-area: parent;
    padding-right: 25px;

    @media(max-width: ${props.theme.breakpoints.max.xl}) {
      padding-right: 0;
    }

    @media(max-width: ${props.theme.breakpoints.max.lg}) {
      order: 40;
      border-top: 1px solid #C4C4C4;
      padding-top: 16px;
    }

    @media(max-width: ${props.theme.breakpoints.max.lg}) and (max-height: 750px) {
      margin-top: auto;
    }

    a{
      &:hover {
        div { 
          background: ${props.theme.colours.darkGreen};
          color: #ffffff;    
        }
      }
    }
  `}

  ${props => props.usedIn === 'login' && props.parent && css`
    @media(max-width: ${props.theme.breakpoints.max.lg}) {
      display: none;
    }
  `}
`;

const ParentHeading = styled.strong`
  display: block;
  font-size: 30px;
  line-height: 32px;
  font-family: ${props => props.theme.font.nimbleFont};
  margin-bottom: 16px;

  @media(max-width: ${props => props.theme.breakpoints.max.xl}) {
    font-size: 25px;
    line-height: 28px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;
  }
`;

const ParentSubHeading = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.colours.neutralText};
  margin-bottom: 20px;
`;

const ParentDescription = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colours.neutralText};
  margin-top: 16px;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
    display: none;
  }
`;

const ParentButton = styled.div`
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  padding: 11px 20px;
  transition: 0.35s ease;
  color: #ffffff;
  margin-top: 16px;
  background: ${props => props.theme.colours.nimbleRed};
  border-radius: 4px;
`;

const ChildIcon = styled.div`
  display: block;
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media(max-width: ${props => props.theme.breakpoints.max.xl}) {
    width: 50px;
    height: 50px;
    margin-bottom: 7px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
    display: none;
  }
`;

const ChildHeading = styled.p`
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;

  @media(max-width: ${props => props.theme.breakpoints.max.xl}) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ChildDescription = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colours.neutralText};
  margin-top: 8px;

  @media(max-width: ${props => props.theme.breakpoints.max.xl}) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const BackButton = styled.li`
  margin: 0;
  padding: 0;
  text-align: left;

  button {
    font-size: 14px;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-bottom: 24px;
  }

  @media (min-width: ${props => props.theme.breakpoints.min.lg}) {
    display: none;
  }

  &::before { 
    content: '';
    display: inline-block;
    margin-right: 5px;
    border-left: 2px solid ${props => props.theme.colours.slate};
    border-bottom: 2px solid ${props => props.theme.colours.slate};
    width: 7px;
    height: 7px;
    transform: rotate(45deg);
  }
`;

const LoginIcon = styled.svg`
  width: 15px;
  margin-right: 10px;

  @media (min-width: ${props => props.theme.breakpoints.min.sm}) {
    display: none;
  }
`;

const DropdownMenu = ({
  menu,
  usedIn,
  currentExpanded,
  setCurrentExpanded
}) => {
  const menuItemClickOutside = e => {
    let buttonElement;
    if (e.target.tagName === 'BUTTON' || e.target.id.includes('DropdownMenu')) {
      buttonElement = e.target;
    } else {
      buttonElement = e.target.parentElement;
    }

    if (buttonElement.id.includes('DropdownMenu')) {
      return;
    }
    setCurrentExpanded('false');
    document.removeEventListener('click', menuItemClickOutside);
  };

  const isCurrent = (e, id) => {
    let buttonId;
    if (e.target.tagName === 'BUTTON') {
      buttonId = e.target.id;
    } else {
      buttonId = e.target.parentElement.id;
    }
    if (currentExpanded === buttonId) {
      setCurrentExpanded('false');
      document.removeEventListener('click', menuItemClickOutside);
    } else {
      setCurrentExpanded(id);
      document.addEventListener('click', menuItemClickOutside);
    }
  };

  const toggleReference = menu.heading.replace(/\s/g, '');

  return (
    <>
      <DropdownMenuToggle
        id={`DropdownMenuToggle${toggleReference}`}
        aria-controls={`DropdownMenu${toggleReference}`}
        aria-expanded={Boolean(currentExpanded)}
        onClick={(e) => isCurrent(e, `DropdownMenuToggle${toggleReference}`)}
        isCurrentExpanded={currentExpanded === `DropdownMenuToggle${toggleReference}`}
        usedIn={usedIn}
        className={currentExpanded === null ? 'hover' : null}
      >
        {usedIn === 'login' ? (
          <LoginIcon width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="loginIconTitle">
            <title id="loginIconTitle">Login icon</title>
            <path className="login-icon-path" fillRule="evenodd" clipRule="evenodd" d="M7.5 1.25C5.77411 1.25 4.375 2.64911 4.375 4.375C4.375 6.10089 5.77411 7.5 7.5 7.5C9.22589 7.5 10.625 6.10089 10.625 4.375C10.625 2.64911 9.22589 1.25 7.5 1.25ZM3.125 4.375C3.125 1.95875 5.08375 0 7.5 0C9.91625 0 11.875 1.95875 11.875 4.375C11.875 6.79125 9.91625 8.75 7.5 8.75C5.08375 8.75 3.125 6.79125 3.125 4.375Z" />
            <path className="login-icon-path" fillRule="evenodd" clipRule="evenodd" d="M4.6488 8.01585C5.55424 7.67489 6.52293 7.5 7.5 7.5C8.47707 7.5 9.44575 7.67489 10.3512 8.01585C11.2567 8.35681 12.083 8.85791 12.7818 9.49312C13.4806 10.1284 14.0385 10.8859 14.4204 11.7241C14.8024 12.5625 15 13.4634 15 14.375C15 14.7202 14.7202 15 14.375 15C13.75 15 13.75 14.7202 13.75 14.375C13.75 13.645 13.5919 12.9206 13.2829 12.2424C12.9738 11.564 12.5189 10.9435 11.9409 10.418C11.3629 9.89252 10.6734 9.47288 9.9107 9.18566C9.14792 8.89843 8.3286 8.75 7.5 8.75C6.6714 8.75 5.85208 8.89843 5.0893 9.18566C4.32656 9.47288 3.63713 9.89252 3.05906 10.418C2.48111 10.9435 2.02618 11.564 1.71707 12.2424C1.40806 12.9206 1.25 13.645 1.25 14.375C1.25 14.7202 0.970178 15 0.625 15C0.279822 15 0 14.7202 0 14.375C0 13.4634 0.197594 12.5625 0.579583 11.7241C0.961477 10.8859 1.51937 10.1284 2.21822 9.49312C2.91695 8.85791 3.74332 8.35681 4.6488 8.01585Z" />
          </LoginIcon>
        )
          : null}
        <span>{menu.heading}</span>
      </DropdownMenuToggle>
      <DropdownMenuLinksContainer
        aria-labelledby={`DropdownMenuToggle${toggleReference}`}
        id={`DropdownMenu${toggleReference}`}
        isCurrentExpanded={currentExpanded === `DropdownMenuToggle${toggleReference}`}
        usedIn={usedIn}
        className={currentExpanded === null ? 'hover' : null}
      >
        <DropdownMenuLinks usedIn={usedIn}>
          {usedIn === 'main' ? (
            <BackButton><button type="button">Main Menu</button></BackButton>
          ) : null}
          {menu.link ? (
            <DropdownLink usedIn={usedIn} parent>
              <a
                href={menu.link.linkUrl}
                target={menu.link.openNewTab ? '_blank' : '_self'}
                rel={menu.link.openNewTab ? 'noreferrer' : null}
              >
                <ParentHeading>{menu.heading}</ParentHeading>
                {menu.subHeading && <ParentSubHeading>{menu.subHeading}</ParentSubHeading>}
                {menu.description && <ParentDescription>{menu.description}</ParentDescription>}
                <ParentButton>{menu.link.linkText}</ParentButton>
              </a>
            </DropdownLink>
          )
            : (
              <StyledLi>
                <ParentHeading>{menu.heading}</ParentHeading>
                {menu.subHeading && <ParentSubHeading>{menu.subHeading}</ParentSubHeading>}
                {menu.description && <ParentDescription>{menu.description} </ParentDescription>}
              </StyledLi>
            )}
          {
            menu.subnav.links.map(link => {
              return (
                <DropdownLink key={link.linkText} usedIn={usedIn}>
                  <a href={link.linkUrl}>
                    {link.linkIcon && (<ChildIcon style={{ backgroundImage: `url(${link.linkIcon.file.url})` }} />)}
                    <ChildHeading>{link.linkText}</ChildHeading>
                    {link.shortDescription && (
                      <ChildDescription>{link.shortDescription}</ChildDescription>
                    )}
                  </a>
                </DropdownLink>
              );
            })
          }
        </DropdownMenuLinks>

      </DropdownMenuLinksContainer>
    </>
  );
};

DropdownMenu.propTypes = {
  menu: PropTypes.shape({
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.shape({
      linkUrl: PropTypes.string,
      linkText: PropTypes.string,
      openNewTab: PropTypes.bool
    }),
    subnav: PropTypes.shape({
      links: PropTypes.arrayOf(PropTypes.shape({
        linkUrl: PropTypes.string,
        linkText: PropTypes.string,
        openNewTab: PropTypes.bool,
        shortDescription: PropTypes.string,
        linkIcon: PropTypes.shape({
          file: PropTypes.objectOf(PropTypes.string)
        })
      }))
    })
  }),
  usedIn: PropTypes.string,
  currentExpanded: PropTypes.string,
  setCurrentExpanded: PropTypes.func
};

export default DropdownMenu;
