import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import MainMenus from './components/mainMenus.components';
import LoginMenu from './components/loginMenu.component';
import HamburgerMenus from './components/hamburgerMenu.component';

const Navwrapper = styled.div`
  min-height: 88px;

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    min-height: 72px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    min-height: 57px;
  }

  @media print {
    display: none !important;
  }
`;

const Apply = styled.div`
  margin: 21px 0px 21px 12px;
  z-index: 30;

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    margin: 15px 0px 15px 6px;
  }

  @media (max-width:  ${props => props.theme.breakpoints.max.sm}) {
    flex: 100%;
    order: 10;
    margin: 0;
    width: 100%;
    display: none;
    margin-bottom: 15px;

    ${props => props.desktop && css`
      display: none !important;
    `};
  }

  @media (max-width:  ${props => props.theme.breakpoints.max.xs}) {
    margin-top: -5px;
    margin-bottom: 10px;
  }

  @media (min-width:  ${props => props.theme.breakpoints.min.sm}) { 
    ${props => props.mobile && css`
      display: none;
    `};
  } 
`;

const Button = styled.a`
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding: 11px 40px;
  transition: 0.35s ease;
  box-sizing: border-box;
  border-radius: 4px;
  color: #ffffff;
  background: ${props => props.theme.colours.darkGreen};

  @media(hover:hover) {
    &:hover {
      background: ${props => props.theme.colours.slate};
      color: #ffffff;    
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    padding: 11px 30px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.xl}) and (min-width: ${props => props.theme.breakpoints.min.lg}) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width:  ${props => props.theme.breakpoints.max.sm}) {
    text-align: center;
    width: 100%;
  }

  @media (max-width:  ${props => props.theme.breakpoints.max.xs}) {
    font-size: 14px;
    line-height: 16px;
    padding: 8px 30px;
  }
`;

const NavSticky = styled.div`
  top: -115px;
  transition: top 0.35s ease;
  max-width: 1980px;
  ${props => props.isSticky && css`
      position: fixed;
      top: 0px;
      width: 100%;
      background: #ffffff;
      z-index: 40;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);

      ${Apply}{
        @media (max-width: ${props.theme.breakpoints.max.sm}) {
          display: block;
        }
      }
  `}
  ${props => props.isStickyFadeOut && css`
    top: -115px;
  `}
`;

const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  padding: 0px 60px;
  max-width: 1403px;
  box-sizing: border-box;
  margin: 0 auto;

  @media(max-width: ${props => props.theme.breakpoints.max.xl}) {
    padding: 0px 30px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    flex-wrap: wrap;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.xs}) {
    padding: 0px 24px;
  }
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  @media (max-width:  ${props => props.theme.breakpoints.max.lg}) {
    justify-content: flex-end;
  }
`;

const LogoContainer = styled.a`
  flex: 0;

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    position: relative;
    z-index: 1200;
  }
`;

const Logo = styled.img`
  display: block;
  width: 108px;
  margin: 26px 0;

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    width: 90px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    margin: 26px 0 20px;
  }

  @media (max-width:  ${props => props.theme.breakpoints.max.xs}) {
    width: 70px;
    margin: 20px 0;
  }
`;

const LoginContainer = styled.div`
  z-index: 30;
  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    z-index: 1200;
  }
`;

const HamburgerContainer = styled.div`
  flex: 1;

  @media (max-width:  ${props => props.theme.breakpoints.max.lg}) {
    flex: 0;
    order: 3;
    margin-left: 24px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    margin-left: 0;
  }
`;

const Header = ({ heroIsFirst }) => {
  const data = useStaticQuery(graphql`
    query NavQuery {
      contentfulNimbleHeaderNavigation(
        componentId: { eq: "Main navigation" }
      ) {
        nimbleLogo {
          file {
            url
          }
        }
        applyButton {
          linkText
          linkUrl
          openNewTab
        }
        menuSection {
          ... on ContentfulLink {
            id
            __typename
            linkUrl
            linkText
            openNewTab
          }
          ... on ContentfulNavigationSection {
            id
            __typename
            heading
            subHeading
            description
            link {
              linkUrl
              linkText
              openNewTab
            } 
            subnav {
              links {
                linkText
                linkUrl
                openNewTab
                shortDescription
                linkIcon {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
        menuSectionLastLinkOnRight
        loginMenuSection {
          heading
          link {
            linkUrl
            openNewTab
          }
          subnav {
            links {
              linkText
              linkUrl
              openNewTab
            }
          }
        }
      }
    }
  `);
  const {
    nimbleLogo,
    applyButton,
    menuSection,
    menuSectionLastLinkOnRight,
    loginMenuSection
  } = data.contentfulNimbleHeaderNavigation;
  const [currentExpanded, setCurrentExpanded] = useState(null);
  const [sticky, setSticky] = useState(false);
  const [stickyFadeOut, setStickyFadeOut] = useState(false);
  const [closeDrawer, setCloseDrawer] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const pageBanner = document.getElementById('components-page').firstElementChild;

      if (pageBanner) {
        const pageBannerBottom = pageBanner.offsetTop + pageBanner.clientHeight;

        if (document.documentElement.scrollTop > pageBannerBottom && sticky === false) {
          setSticky(true);
        } else if (document.documentElement.scrollTop < pageBannerBottom && sticky === true) {
          setStickyFadeOut(true);
          setCurrentExpanded(null);
          if (document.documentElement.scrollTop < 200) {
            setSticky(false);
            setStickyFadeOut(false);
          } else {
            setTimeout(() => {
              setSticky(false);
              setStickyFadeOut(false);
            }, 400);
          }
        }
      }
    };

    if (heroIsFirst) {
      window.addEventListener('scroll', handleScroll);
    } else {
      setSticky(true);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sticky, heroIsFirst]);

  const handleDrawerClose = () => {
    setCloseDrawer(!closeDrawer);
  };

  return (
    <Navwrapper>
      <NavSticky isSticky={sticky} isStickyFadeOut={stickyFadeOut}>
        <NavContainer>
          <LogoContainer href="/">
            <Logo src={nimbleLogo.file.url} alt="Nimble Logo" />
          </LogoContainer>

          <RightContainer>
            <HamburgerContainer>
              <HamburgerMenus closeDrawer={closeDrawer}>
                <MainMenus
                  menuData={menuSection}
                  menuSectionLastLinkOnRight={menuSectionLastLinkOnRight}
                  currentExpanded={currentExpanded}
                  setCurrentExpanded={setCurrentExpanded}
                />
              </HamburgerMenus>
            </HamburgerContainer>

            <LoginContainer onClick={handleDrawerClose}>
              <LoginMenu
                loginMenuData={loginMenuSection}
                currentExpanded={currentExpanded}
                setCurrentExpanded={setCurrentExpanded}
              />
            </LoginContainer>
            {applyButton && (
              <Apply desktop>
                <Button href={applyButton.linkUrl} target={applyButton.openNewTab ? '_blank' : '_self'}>
                  {applyButton.linkText}
                </Button>
              </Apply>
            )}
          </RightContainer>
          {applyButton && (
            <Apply mobile>
              <Button href={applyButton.linkUrl} target={applyButton.openNewTab ? '_blank' : '_self'}>
                {applyButton.linkText}
              </Button>
            </Apply>
          )}
        </NavContainer>
      </NavSticky>
    </Navwrapper>
  );
};

Header.propTypes = {
  heroIsFirst: PropTypes.bool
};

export default Header;
