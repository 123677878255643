import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const StyledLink = styled.a`
  display: block;
  position: relative;
  font-family: ${props => props.theme.font.nimbleFont};
  color: ${props => props.theme.colours.slate};
  padding: 22px 10px;
  transition: 0.35s ease;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    z-index: 21;
    border-bottom: 2px solid transparent;
    transition: 0.35s ease;
  }

  @media(hover: hover) {
    @media(min-width: ${props => props.theme.breakpoints.min.lg})  {
      &:hover{
        &::after {
          width: 100%;
          border-bottom-color: ${props => props.theme.colours.nimbleRed};
        }
      }
    }

    @media(max-width: ${props => props.theme.breakpoints.max.lg})  {
      &:hover {
        color: ${props => props.theme.colours.darkGreen};
      }
    }
  }

  /* main menu styles */
  ${props => props.usedIn === 'main' && css`
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;

    @media(max-width: ${props.theme.breakpoints.max.xl}) and (min-width: ${props.theme.breakpoints.min.lg}) {
      font-size: 14px;
      line-height: 20px;
    }

    @media(max-width: ${props.theme.breakpoints.max.lg}) {
      font-size: 20px;
      padding: 20px 0;
    }

    @media(max-width: ${props.theme.breakpoints.max.sm}) {
      padding: 0;
      margin: 30px 0;
    }
  `}
`;

const DropdownMenu = ({
  link,
  usedIn
}) => {
  return (
    <StyledLink href={link.linkUrl} usedIn={usedIn}>{link.linkText}</StyledLink>
  );
};

DropdownMenu.propTypes = {
  link: PropTypes.shape({
    linkText: PropTypes.string,
    linkUrl: PropTypes.string
  }),
  usedIn: PropTypes.string
};

export default DropdownMenu;
