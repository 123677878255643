import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import CardsCarousel from './carousel';
import CardsGrid from './grid';

const StyledSection = styled.div`
  padding: 64px 0px;
  ${props => props.background === 'White' && css`
    background: #ffffff;
  `}

  ${props => props.background === 'Red' && css`
    background: ${props.theme.colours.nimbleRed};
  `}

  ${props => props.background === 'Pink' && css`
    background: ${props.theme.colours.pink};
  `}

  ${props => props.background === 'Grey' && css`
    background: ${props.theme.colours.greyBack};
  `}

  ${props => props.background === 'Cream' && css`
    background: ${props.theme.colours.cream};
  `}

  ${props => props.background === 'Green' && css`
    background: ${props.theme.colours.lightGreen};
  `}

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 40px 0px;
  }
`;

const StyledContainer = styled.div`
  padding: 0px 60px;
  max-width: 1403px;
  margin: auto;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    padding: 0px 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 0px 24px;
  }
`;

const HeadContent = styled.div` 
  ${props => props.padding && css`
    @media(min-width: ${props.theme.breakpoints.min.xs}) {
      padding-right: 90px;
    }
  `}
`;

const StyledHeading = styled.h2`
  color: ${props => props.theme.colours.black};
  font-family: ${props => props.theme.font.nimbleFont};
  font-style: normal;
  font-size: 29px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 7px;

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 20px;
    line-height: 24px; 
  }  
`;

const StyledSubheading = styled.p`
  color: ${props => props.theme.colours.black};
  margin: 0;

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 14px;
    line-height: 20px; 
  } 
`;

export default function CardsComponent({ data }) {
  const {
    componentBackground,
    cardsHeading: heading,
    cardsSubheading: subheading,
    displayAs,
    columns,
    cards
  } = data;

  return (
    <StyledSection className="cards" background={componentBackground}>
      <StyledContainer>
        <HeadContent padding={displayAs === 'Carousel'} className="cards-head-content">
          {heading && <StyledHeading className="cards-heading">{heading}</StyledHeading>}
          {subheading && <StyledSubheading className="cards-subheading">{subheading}</StyledSubheading>}
        </HeadContent>

        {displayAs === 'Carousel' ? <CardsCarousel cards={cards} columns={columns} /> : <CardsGrid cards={cards} columns={columns} />}
      </StyledContainer>
    </StyledSection>
  );
}

CardsComponent.propTypes = {
  data: PropTypes.shape({
    componentBackground: PropTypes.string,
    cardsHeading: PropTypes.string,
    cardsSubheading: PropTypes.string,
    displayAs: PropTypes.string,
    columns: PropTypes.number,
    cards: PropTypes.arrayOf(PropTypes.shape({
      heading: PropTypes.string,
      shortDescription: PropTypes.string,
      longDescription: PropTypes.objectOf(PropTypes.string),
      link: PropTypes.shape({
        linkUrl: PropTypes.string,
        linkText: PropTypes.string,
        openNewTab: PropTypes.bool
      }),
      image: PropTypes.shape({
        file: PropTypes.objectOf(PropTypes.string)
      }),
      imageAs: PropTypes.string,
      iconBackground: PropTypes.string
    }))
  })
};
