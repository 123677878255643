/* eslint-disable react/prop-types */
import React from 'react';
import loadable from '@loadable/component';
// Pages
// import ApplePayLandingV2 from '../applePayLanding-v2';

const StaticPages = ({ staticPage, componentData }) => {
  const renderSwitch = (pages, index) => {
    switch (pages) {
      case 'homepage':
        const Homepage = loadable(() => import(`./homepage`));
        return (
          <Homepage
            key={index}
            pageData={staticPage}
            componentData={componentData}
          />
        );
      case 'nimbleAnytime':
        const NimbleAnytime = loadable(() => import(`./LandingPages/anytime`));
        return (
          <NimbleAnytime
            key={index}
            pageData={staticPage}
            componentData={componentData}
          />
        );
      case 'inspectRealEstate':
        const InspectRealEstate = loadable(() => import(`./LandingPages/inspectRealEstate`));
        return (
          <InspectRealEstate
            key={index}
            pageData={staticPage}
            componentData={componentData}
          />
        );
      case 'nimbleMediumLoan':
        const NimbleMediumLoan = loadable(() =>
          import(`./LandingPages/mediumLoan`)
        );
        return (
          <NimbleMediumLoan
            key={index}
            pageData={staticPage}
            componentData={componentData}
          />
        );
      case 'nimbleCarLoan':
        const NimbleCarLoan = loadable(() => import(`./LandingPages/carLoan`));
        return (
          <NimbleCarLoan
            key={index}
            pageData={staticPage}
            componentData={componentData}
          />
        );
      case 'nimbleSmallLoan':
        const NimbleSmallLoan = loadable(() =>
          import(`./LandingPages/smallLoan`)
        );
        return (
          <NimbleSmallLoan
            key={index}
            pageData={staticPage}
            componentData={componentData}
          />
        );
      case 'applePayLanding':
        const ApplePayLanding = loadable(() =>
          import(`./LandingPages/applePay`)
        );
        return <ApplePayLanding key={index} data={staticPage} />;
      case 'rioGiveaway':
        const RioGiveaway = loadable(() => import(`./rioGiveaway`));
        return (
          <RioGiveaway
            key={index}
            pageData={staticPage}
            componentData={componentData}
          />
        );
      case 'aboutUs':
        const AboutUs = loadable(() => import(`./aboutUs`));
        return (
          <AboutUs
            key={index}
            pageData={staticPage}
            componentData={componentData}
          />
        );
      case 'scholarship':
        const Scholarship = loadable(() => import(`./scholarship`));
        return (
          <Scholarship
            key={index}
            pageData={staticPage}
            componentData={componentData}
          />
        );
      case 'Error404Page':
        const NimbleError404Page = loadable(() => import(`./Error404`));
        return (
          <NimbleError404Page
            key={index}
            pageData={staticPage}
            componentData={componentData}
          />
        );
      // case "sandbox":
      //   return <Sandbox key={index} data={staticPage} />
      default:
        return null;
    }
  };

  return (
    <div id="components-page">
      {renderSwitch(JSON.parse(staticPage.internal.content).page)}
    </div>
  );
};

export default StaticPages;
