/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const Drawer = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 200;
    height: 100vh;
    background-color: white;
    width: 450px;
    padding: 50px 0 0;
    transform: translate(100%);
    transition: transform 0.35s ease;
    ${props =>
      props.open &&
      css`
        transform: translate(0%);
      `}
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    width: 100%;
    padding: 60px 0 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    padding: 45px 0 0;
  }
`;

const SideMenuMask = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    z-index: 100;
    background-color: #000000;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.3;
    display: none;

    ${props =>
      props.open &&
      css`
        display: block;
      `}
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    background-color: transparent;
  }
`;

const HamburgerButton = styled.button`
  position: relative;
  top: 2px;
  left: 2px;
  height: 100%;
  width: 20px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;

  .hamburger-icon-path {
    transition: 0.35s ease;
    fill: ${props => props.theme.colours.slate};
  }

  &:hover {
    .hamburger-icon-path {
      fill: ${props => props.theme.colours.darkGreen};
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.min.lg}) {
    display: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    top: 5px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    top: 0px;
  }
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 200;

  @media (min-width: ${props => props.theme.breakpoints.min.lg}) {
    display: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    top: 12px;
    right: 10px;
  }
  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    top: 2px;
    right: 6px;
  }
`;

const MenuContainer = styled.ul`
  box-sizing: border-box;
  @media (min-width: ${props => props.theme.breakpoints.min.lg}) {
    display: flex;
    align-items: center;
    flex: 1;
    margin-left: 18px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 100%;
    padding: 0 40px 20px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 0 30px 20px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    padding: 0 24px 20px;
  }
`;

const SideMenu = ({ closeDrawer, children }) => {
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';
  };
  const handleDrawerClose = () => {
    if (open === true) {
      setOpen(false);
      document.documentElement.removeAttribute('style');
      document.body.removeAttribute('style');
    }
  };

  useEffect(() => {
    handleDrawerClose();
  }, [closeDrawer]);

  return (
    <>
      <SideMenuMask open={open} onClick={handleDrawerClose} />
      <div>
        <HamburgerButton
          onClick={handleDrawerOpen}
          onKeyDown={handleDrawerOpen}
          aria-label="Open Side Menu"
          type="button"
        >
          <svg
            width="20"
            height="14"
            viewBox="0 0 20 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="hamburger-icon-path" width="20" height="2" />
            <rect
              className="hamburger-icon-path"
              y="12"
              width="20"
              height="2"
            />
            <rect className="hamburger-icon-path" y="6" width="20" height="2" />
          </svg>
        </HamburgerButton>
        <Drawer variant="persistent" anchor="right" open={open}>
          <CloseButtonContainer>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </CloseButtonContainer>
          <MenuContainer>{children}</MenuContainer>
        </Drawer>
      </div>
    </>
  );
};

SideMenu.propTypes = {
  closeDrawer: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default SideMenu;
