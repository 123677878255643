import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import CardsCard from './card';

const Grid = styled.div`
  display: grid;
  grid-gap: 20px 0;
  margin-top: 24px;
  margin-left: -12px;
  margin-right: -12px;

  ${props => props.columns === 6 && css`
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    @media(max-width: ${props.theme.breakpoints.max.xl}){
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    @media(max-width: 1050px){
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media(max-width: 850px){
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media(max-width: 690px){
      grid-template-columns: 1fr 1fr;
    }

    @media(max-width: ${props.theme.breakpoints.max.xs}){
      grid-template-columns: 1fr;
    }
  `}

  ${props => props.columns === 5 && css`
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    @media(max-width: ${props.theme.breakpoints.max.xl}){
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media(max-width: ${props.theme.breakpoints.max.lg}){
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media(max-width: ${props.theme.breakpoints.max.md}){
      grid-template-columns: 1fr 1fr;
    }

    @media(max-width: ${props.theme.breakpoints.max.sm}){
      grid-template-columns: 1fr;
    }
  `}

  ${props => props.columns === 4 && css`
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media(max-width: ${props.theme.breakpoints.max.xl}){
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media(max-width: ${props.theme.breakpoints.max.lg}){
      grid-template-columns: 1fr 1fr;
    }

    @media(max-width: 675px){
      grid-template-columns: 1fr;
    }
  `}

  ${props => props.columns === 3 && css`
    grid-template-columns: 1fr 1fr 1fr;

    @media(max-width: ${props.theme.breakpoints.max.xl}){
      grid-template-columns: 1fr 1fr;
    }

    @media(max-width: 850px){
      grid-template-columns: 1fr;
    }
  `}

  ${props => props.columns === 2 && css`
    grid-template-columns: 1fr 1fr;
    @media(max-width: ${props.theme.breakpoints.max.lg}){
      grid-template-columns: 1fr;
    }
  `} 
`;

export default function CardsGrid({ cards, columns }) {
  return (
    <Grid columns={columns}>
      {cards.map((card) => (
        <CardsCard
          key={`${card.heading}-${card.shortDescription}-${columns}`}
          columns={columns}
          heading={card.heading}
          shortDescription={card.shortDescription}
          longDescription={card.longDescription}
          link={card.link}
          displayButton={card.displayButton}
          image={card.image && card.image.file.url}
          imageAs={card.imageAs}
          iconBackground={card.iconBackground}
        />
      ))}
    </Grid>
  );
}

CardsGrid.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({
    heading: PropTypes.string,
    shortDescription: PropTypes.string,
    longDescription: PropTypes.objectOf(PropTypes.string),
    link: PropTypes.shape({
      linkUrl: PropTypes.string,
      linkText: PropTypes.string,
      openNewTab: PropTypes.bool
    }),
    displayButton: PropTypes.boolean,
    image: PropTypes.shape({
      file: PropTypes.objectOf(PropTypes.string)
    }),
    imageAs: PropTypes.string,
    iconBackground: PropTypes.string
  })),
  columns: PropTypes.number
};
