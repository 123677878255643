import { useStaticQuery, graphql } from 'gatsby';

export const AllContentfulTooltips = () => {
  const tooltipsQuery = useStaticQuery(
    graphql`
      query tooltipsQuery {
        allContentfulTooltip {
          edges {
            node {
              tooltipId
              tooltipText {
                raw
              }
            }
          }
        }
      }
    `
  );

  const tooltips = [];

  tooltipsQuery.allContentfulTooltip.edges.map(item => {
    return tooltips.push({ ...item.node });
  });

  return tooltips;
};
