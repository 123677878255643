import React from 'react';
import PropTypes from 'prop-types';

const insertTooltip = (text) => {
  const fullTooltipRegex = /{tooltip:\d*}/gm;
  const fullTooltip = text.match(fullTooltipRegex).toString();
  const tooltipNumberRegex = /\d\d*/gm;
  const tooltipNumber = fullTooltip.match(tooltipNumberRegex);
  return <>{text.split(/{tooltip:\d*}/gm).flatMap((textChildren, i) => [i > 0 && <span key={`${tooltipNumber[i - 1]}-${i}`} className="nimble-tooltip" data-number={tooltipNumber[i - 1]} />, textChildren])}</>;
};

export default insertTooltip;

insertTooltip.propTypes = {
  text: PropTypes.string.isRequired
};

export const shouldInsertTooltip = (text) => {
  if (text && text.includes('{tooltip')) {
    return insertTooltip(text);
  }
  return text;
};

shouldInsertTooltip.propTypes = {
  text: PropTypes.string.isRequired
};
