import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RichText from '../../elements/richText';

const StyledTextContainer = styled.div`
  max-width: 982px;
  margin-bottom: 10px;
  margin: auto;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 30px;
  background: ${props => props.theme.colours.cream};
  color: ${props => props.theme.colours.slate};
  & a {
    color: ${props => props.theme.colours.slate};
  }

  @media print {
    display: none !important;
  }
`;

const StyledDisclamerText = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  & b {
    font-family: 'SharpGroteskSmBold';
  }
  & u {
    text-decoration: none;
  }
`;

const WarningBtn = styled.a`
  color: ${props => props.theme.colours.slate};
  padding: 12px 20px;
  transition: 0.35s ease;
  line-height: 28px;
  border-radius: 4px;
  border: 1px solid #A8A8A8;
  background: transparent;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  path {
    fill: ${props => props.theme.colours.slate};
    transition: 0.35s ease;
  }

  &:hover{
    background: ${props => props.theme.colours.slate};
    color: #ffffff;

    path {
      fill: #ffffff;
    }
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 19px;
  }
`;

const StyledIcon = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const warningIcon = (
  <StyledIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.99 78.8">
    <path d="M3.23,78.8H86.76A3.23,3.23,0,0,0,90,75.57,3.27,3.27,0,0,0,89.56,74L47.79,1.62a3.22,3.22,0,0,0-5.59,0L.43,74a3.23,3.23,0,0,0,2.8,4.85ZM40.65,23.61a4.35,4.35,0,1,1,8.69,0h0V51.53a4.35,4.35,0,0,1-8.69,0ZM45,61.94a5,5,0,1,1-5,5h0a5,5,0,0,1,5-5Z" />
  </StyledIcon>
);

const FooterDisclaimers = ({
  data
}) => {
  return (
    <StyledContainer>
      <StyledTextContainer>
        {data.map((disclaimer) => (
          <StyledDisclamerText key={disclaimer}>
            {disclaimer.text && (
              <div><RichText text={disclaimer.text} /></div>
            )}
          </StyledDisclamerText>
        ))}
      </StyledTextContainer>
      <WarningBtn href="https://nimble.com.au/warning-about-borrowing/">
        {warningIcon}
        Warning about borrowing
      </WarningBtn>
    </StyledContainer>
  );
};

FooterDisclaimers.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      childContentfulCommonTextTextRichTextNode: PropTypes.shape({
        childContentfulRichText: PropTypes.objectOf(PropTypes.string)
      })
    })
  )
};
export default FooterDisclaimers;
