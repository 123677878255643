import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CardsComponent from '../../../components/cards';
import confetti from '../../../../static/images/confetti.jpg';

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  padding: 10px 60px;
  max-width: 1403px;

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    padding: 10px 40px;
    display: block;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 10px 30px 20px;
  }

  @media print {
    display: none !important;
  }
`;

const Cta = styled.div`

  .cards {
    background-image: url('${confetti}');
    background-size: cover;
    background-position: center right;

    .cards-card-background{
      background-color: rgba(255, 255, 255, 0.75);
      padding: 6px;
      @media (hover: hover) {
        &:hover {
          box-shadow: 0px 3px 8px 1px rgba(9, 30, 66, 0.2),
            0px 0px 1px rgba(9, 30, 66, 0.31);
          transform: perspective(500px) translateZ(15px);

          .button {
            background-color: ${props => props.theme.colours.slate};
            border-color: ${props => props.theme.colours.slate};
          }
        }
      }
      
      p {
         line-height: 1.4;
      }
    }
  }

  @media print {
    display: none !important;
  }
`;

const FooterSocials = styled.div`
  color: ${props => props.theme.colours.white};
  background-color: ${props => props.theme.colours.green};
`;

const SocialsTitle = styled.h2`
  margin: 20px 30px 20px 0;
`;

const SocialsLinks = styled.ul`
  display: flex;
  list-style: none;

  li {
    line-height: normal;
    padding: 0;
    margin: 0 10px 0;

    @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
      margin: 0 7px 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }
`;

const SocialLink = styled.a`
  transition: 0.35s ease;
  @media (hover: hover) {
    &:hover {
      opacity: 0.75;
    }
  }
`;

const SocialIconWrapper = styled.span`
  border: 1px solid ${props => props.theme.colours.white};
  padding: 7px;
  border-radius: 50%;
  display: block;
  width: 30px;
  height: 30px;

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    width: 20px;
    height: 20px;
  }
`;

const SocialIcon = styled.img`
  width: 100%;
  height: 100%;
`;

const PreFooter = ({ socialMediaLinksData }) => {
  const data = useStaticQuery(graphql`
    query FooterCtaQuery {
      contentfulComponentCards(componentId: { eq: "Footer Cta" }) {
        componentBackground
        cardsHeading: heading
        cardsSubheading: subheading
        displayAs
        columns
        cards {
          heading
          shortDescription
          longDescription {
            raw
          }
          link {
            linkUrl
            linkText
            openNewTab
          }
          displayButton
          image {
            file {
              url
            }
          }
          imageAs
          iconBackground
        }
      }
    }
  `);

  return (
    <>
      <Cta>
        <CardsComponent data={data.contentfulComponentCards} />
      </Cta>
      <FooterSocials>
        <StyledContainer>
          <SocialsTitle>{socialMediaLinksData.title}</SocialsTitle>
          <SocialsLinks>
            {/* {JSON.stringify(socialMediaLinksData.components)} */}
            {socialMediaLinksData.components.map(
              ({ id, linkText, linkUrl, linkIcon }) => (
                <>
                  {linkUrl && linkIcon && linkIcon.file && (
                    <li key={id}>
                      <SocialLink
                        target="_blank"
                        href={linkUrl}
                        className="margin-small-right"
                      >
                        <SocialIconWrapper>
                          <SocialIcon src={linkIcon.file.url} alt={linkText} />
                        </SocialIconWrapper>
                      </SocialLink>
                    </li>
                  )}
                </>
              )
            )}
          </SocialsLinks>
        </StyledContainer>
      </FooterSocials>
    </>
  );
};

PreFooter.propTypes = {
  socialMediaLinksData: PropTypes.shape({
    components: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        linkText: PropTypes.string,
        linkUrl: PropTypes.string,
        linkIcon: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string
          })
        })
      })
    ),
    title: PropTypes.string
  })
};
export default PreFooter;
