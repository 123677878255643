import React from 'react';
import PropTypes from 'prop-types';
import DropdownMenu from './dropdownMenu.component';

const LoginMenu = ({
  loginMenuData,
  currentExpanded,
  setCurrentExpanded
}) => {
  return (
    <DropdownMenu menu={loginMenuData} usedIn="login" currentExpanded={currentExpanded} setCurrentExpanded={setCurrentExpanded} />
  );
};
LoginMenu.propTypes = {
  loginMenuData: PropTypes.shape({
    heading: PropTypes.string,
    link: PropTypes.shape({
      linkUrl: PropTypes.string,
      linkText: PropTypes.string,
      openNewTab: PropTypes.bool
    }),
    subnav: PropTypes.shape({
      links: PropTypes.arrayOf(PropTypes.shape({
        linkUrl: PropTypes.string,
        linkText: PropTypes.string,
        openNewTab: PropTypes.bool
      }))
    })
  }),
  currentExpanded: PropTypes.string,
  setCurrentExpanded: PropTypes.func
};

export default LoginMenu;
