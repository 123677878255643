import React from 'react';
import loadable from '@loadable/component';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import CardsCard from './card';

const Carousel = loadable(() => import('../../elements/slickSlider'));

const CarouselContainer = styled.div` 
  margin-top: 4px;
  margin-left: -12px;
  margin-right: -12px;

  .slick-track {
    display: flex !important;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .slick-slide {
    height: inherit !important;
    transition: opacity 0.25s ease;

    & > div {
      height: 100%;
    }
  }

  .slick-next, .slick-prev {
    top: -44px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${props => props.theme.colours.darkGreen};

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 47%;
      width: 7px;
      height: 7px;
      border-top: 2px solid #ffffff;
      transition: 0.35s ease;
    }

    &:focus, &:hover{
      background-color: ${props => props.theme.colours.darkGreen};
    }

    &.slick-disabled {
      background-color: ${props => props.theme.colours.greyBack};
      &::before {
        opacity: 0.35;
        border-color:${props => props.theme.colours.black};
      }

      &:focus, &:hover{
        background-color: ${props => props.theme.colours.greyBack};
      }
    }
  }

  .slick-prev {
    left: auto;
    right: 55px;
    &::before {
      left: 15px;
      border-left: 2px solid #ffffff;
      transform: rotate(-45deg) translateY(-50%);
    }

    &:focus, &:hover{
      &::before {
       left: 13px;
      }
    }

    &.slick-disabled {
      &:focus, &:hover{
        &::before {
        left: 15px;
        }
      }
    }
  }

  .slick-next {
    right: 10px;
    &::before {
      left: 6px;
      border-right: 2px solid #ffffff;
      transform: rotate(45deg) translateY(-50%);
    }

    &:focus, &:hover{
      &::before {
       left: 8px;
      }
    }

    &.slick-disabled {
      &:focus, &:hover{
        &::before {
        left: 6px;
        }
      }
    }
  } 
  
  @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
    ${props => props.columns === 2 && css`
      margin-left: -60px;
      margin-right: -60px;
      .slick-list{
        padding-left: 50px !important;
      }
      .slick-prev {
        right: 105px;
      }
      .slick-next {
        right: 60px;
      } 
    `}
  }

  @media(max-width: 850px) { 
    ${props => props.columns === 3 && css`    
        margin-left: -60px;
        margin-right: -60px;

        .slick-list{
          padding-left: 50px !important;
        }        
        .slick-prev {
          right: 105px;
        }
        .slick-next {
          right: 60px;
        } 
    `}
  }

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    ${props => props.columns <= 3 && css`
      margin-left: -40px;
      margin-right: -40px;

      .slick-list{
        padding-left: 30px !important;
      }
      .slick-prev {
        right: 83px;
      }
      .slick-next {
        right: 38px;
      } 
    `}

  }

  @media(max-width: 675px) {
    ${props => props.columns === 4 && css`
      margin-left: -40px;
      margin-right: -40px;

      .slick-list{
        padding-left: 30px !important;
      }
      .slick-prev {
        right: 83px;
      }
      .slick-next {
        right: 38px;
      }     
    `}
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    ${props => props.columns <= 5 && css`
      margin-left: -24px;
      margin-right: -24px;

      .slick-list{
        padding-left: 10px !important;
      }
      .slick-prev {
        right: 67px;
      }
      .slick-next {
        right: 22px;
      } 
    `}
  }

  @media(max-width: ${props => props.theme.breakpoints.max.xs}) {
    padding-top: 34px;

    .slick-next, .slick-prev {
      top: -26px;
    }

    .slick-prev {
      left: 20px;
      right: auto;
    }

    .slick-next {
      left: 64px;
      right: auto;
    }

    ${props => props.columns === 6 && css`
      margin-left: -24px;
      margin-right: -24px;

      .slick-list{
        padding-left: 10px !important;
      }
    `}
  } 
`;

export default function CardsCarousel({ cards, columns }) {
  let columnSettings = {};

  if (columns === 6) {
    columnSettings = {
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 1050,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 690,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '20%'
          }
        },
        {
          breakpoint: 350,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '50px'
          }
        }
      ]
    };
  } else if (columns === 5) {
    columnSettings = {
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '20%'
          }
        },
        {
          breakpoint: 350,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '50px'
          }
        }
      ]
    };
  } else if (columns === 4) {
    columnSettings = {
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 675,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '20%'
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '50px'
          }
        }
      ]
    };
  } else if (columns === 3) {
    columnSettings = {
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '175px'
          }
        },
        {
          breakpoint: 675,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '116px'
          }
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '15%'
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '24px'
          }
        }
      ]
    };
  } else if (columns === 2) {
    columnSettings = {
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '100px'
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '75px'
          }
        },
        {
          breakpoint: 675,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '45px'
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '24px'
          }
        }
      ]
    };
  }

  const carouselSettings = {
    slidesToShow: columns,
    infinite: false,
    arrows: true,
    dots: false,
    mobileFirst: false,
    ...columnSettings
  };

  return (
    <CarouselContainer columns={columns}>
      <Carousel settings={carouselSettings}>
        {cards.map((card) => (
          <CardsCard
            key={`${card.heading}-${card.shortDescription}-${columns}`}
            columns={columns}
            heading={card.heading}
            shortDescription={card.shortDescription}
            longDescription={card.longDescription}
            link={card.link}
            displayButton={card.displayButton}
            image={card.image && card.image.file.url}
            imageAs={card.imageAs}
            iconBackground={card.iconBackground}
          />
        ))}
      </Carousel>
    </CarouselContainer>
  );
}

CardsCarousel.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({
    heading: PropTypes.string,
    shortDescription: PropTypes.string,
    longDescription: PropTypes.objectOf(PropTypes.string),
    link: PropTypes.shape({
      linkUrl: PropTypes.string,
      linkText: PropTypes.string,
      openNewTab: PropTypes.bool
    }),
    displayButton: PropTypes.boolean,
    image: PropTypes.shape({
      file: PropTypes.objectOf(PropTypes.string)
    }),
    imageAs: PropTypes.string,
    iconBackground: PropTypes.string
  })),
  columns: PropTypes.number
};
