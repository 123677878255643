import React from 'react';
import PropTypes from 'prop-types';
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import insertTooltip from '../../hooks/tooltips/insertTooltip';

const handleNode = (node, children, Tag) => {
  const { content } = node;

  if (content[0].value) {
    let container = <></>;
    return (
      <Tag>
        {content.map((item, index) => {
          if (item.value && item.value.includes('{tooltip')) {
            container = insertTooltip(item.value);
          } else {
            container = <>{item.value}</>;
          }

          if (item.marks && item.marks.length) {
            item.marks.forEach((mark) => {
              let newContainer = null;

              if (mark.type === 'bold') {
                newContainer = <strong>{container}</strong>;
                container = newContainer;
              } else if (mark.type === 'italic') {
                newContainer = <em>{container}</em>;
                container = newContainer;
              } else if (mark.type === 'underline') {
                newContainer = <u>{container}</u>;
                container = newContainer;
              }
            });
          }

          if (item.value) {
            return <React.Fragment key={item.value}>{container}</React.Fragment>;
          }

          return <React.Fragment key={`${children[index].type}-${children[index].key}`}>{children[index]}</React.Fragment>;
        })}
      </Tag>
    );
  }

  return <Tag>{children}</Tag>;
};

const RichText = ({ text }) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: copy => <strong>{copy}</strong>,
      [MARKS.ITALIC]: copy => <em>{copy}</em>,
      [MARKS.UNDERLINE]: copy => <u>{copy}</u>
    },
    renderNode: {
      [BLOCKS.DOCUMENT]: (node, children) => <>{children}</>,
      [BLOCKS.PARAGRAPH]: (node, children) => handleNode(node, children, 'p'),
      [BLOCKS.HEADING_1]: (node, children) => handleNode(node, children, 'h1'),
      [BLOCKS.HEADING_2]: (node, children) => handleNode(node, children, 'h2'),
      [BLOCKS.HEADING_3]: (node, children) => handleNode(node, children, 'h3'),
      [BLOCKS.HEADING_4]: (node, children) => handleNode(node, children, 'h4'),
      [BLOCKS.HEADING_5]: (node, children) => handleNode(node, children, 'h5'),
      [BLOCKS.HEADING_6]: (node, children) => handleNode(node, children, 'h6'),
      [BLOCKS.OL_LIST]: (node, children) => handleNode(node, children, 'ol'),
      [BLOCKS.UL_LIST]: (node, children) => handleNode(node, children, 'ul'),
      [BLOCKS.TABLE]: (node, children) => handleNode(node, children, 'table'),
      [BLOCKS.TABLE_ROW]: (node, children) => handleNode(node, children, 'tr'),
      [BLOCKS.TABLE_CELL]: (node, children) => handleNode(node, children, 'td'),
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => handleNode(node, children, 'th'),
      [BLOCKS.LIST_ITEM]: (node, children) => handleNode(node, children, 'li'),
      [INLINES.HYPERLINK]: (node, children) => {
        const { data } = node;
        return <a href={data.uri}>{children}</a>;
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const { data } = node;

        if (data.target.file) {
          return <a href={data.target.file.url} target="_blank" rel="noreferrer">{children}</a>;
        }

        return '';
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { data } = node;
        return <img src={data.target} alt="" />;
      }
    }
  };

  return (
    <>
      {text && text.raw && renderRichText(text, options)}
    </>
  );
};

export default RichText;

RichText.propTypes = {
  text: PropTypes.shape({
    raw: PropTypes.string,
    references: PropTypes.arrayOf(PropTypes.object)
  })
};
