/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const MajorLinkTitle = styled.h2`
  font-family: ${props => props.theme.font.nimbleFont};
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  font-style: normal;
  color: #ffffff;
  margin-bottom: 12px;

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    display: none;
  }
`;
const MajorLink = styled.a`
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 8px;
  transition: 0.35s ease;

  &:hover {
    color: #26cfb6;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    font-size: 18px;
    line-height: 40px;
  }
`;
const MajorLinkContainer = styled.div`
  flex: 0 0 25%;
  padding-left: 15px;
  padding-right: 15px;
  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    margin-top: 50px;
    padding-left: 0;
    padding-right: 0;
  }
`;

const MajorLinkMobileToggle = styled.button`
  display: block;
  position: relative;
  width: 100%;
  text-align: left;
  color: #ffffff;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 12px;
  background-color: transparent;
  border: 0;
  padding: 0 30px 0 0;

  &::after {
    content: '+';
    display: block;
    position: absolute;
    top: 6px;
    right: 0;
    transition: 0.35s ease;
    width: 19px;
    height: 19px;
    font-size: 30px;
    font-family: Arial;
    line-height: 19px;
    transform: translate3d(0, 0, 0);
  }

  ${props => props.expanded && css`
    &::after {
      transform: translate3d(0, 0, 0) rotate(225deg);
    }
  `}

  @media (min-width: ${props => props.theme.breakpoints.min.md}) {
    display: none;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    max-height: 0px;
    overflow: hidden;
    transition: 0.5s ease;

    ${props => props.expanded && css`
      max-height: 1200px;
      transition: 0.75s ease;
    `}
  }
`;

const MajorLinks = ({
  number,
  linkgroup
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <MajorLinkContainer number={number}>
      <MajorLinkTitle>{linkgroup.title}</MajorLinkTitle>
      <MajorLinkMobileToggle
        id={`majorLinksToggle${number}`}
        aria-controls={`majorLinks${number}`}
        aria-expanded="false"
        onClick={() => setExpanded(!expanded)}
        expanded={expanded}
      >
        {linkgroup.title}
      </MajorLinkMobileToggle>
      <LinksContainer
        aria-labelledby={`majorLinksToggle${number}`}
        id={`majorLinks${number}`}
        expanded={expanded}
      >
        {
          linkgroup.components.map(link => {
            return (
              <MajorLink href={link.linkUrl} key={link.id}>{link.linkText}</MajorLink>
            );
          })
        }
      </LinksContainer>
    </MajorLinkContainer>
  );
};

export default MajorLinks;
