import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import RichText from '../../elements/richText';

const ContentWrapper = styled.div`
  margin-bottom: 20px;
`;

const Image = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled.div`
  height: 154px;

  //ImageAs
  ${props =>
    props.imageAs === 'Icon' &&
    css`
      padding: 20px;
      ${Image} {
        background-size: contain;
      }
    `}

  ${props =>
    props.imageAs === 'Photo' &&
    css`
      ${Image} {
        background-size: cover;
      }
    `}

  //Background colours
  ${props =>
    props.iconBackground === 'White' &&
    css`
      background: #ffffff;
    `}

  ${props =>
    props.iconBackground === 'Red' &&
    css`
      background: ${props.theme.colours.nimbleRed};
    `}

  ${props =>
    props.iconBackground === 'Pink' &&
    css`
      background: ${props.theme.colours.pink};
    `}

  ${props =>
    props.iconBackground === 'Grey' &&
    css`
      background: ${props.theme.colours.greyBack};
    `}

  ${props =>
    props.iconBackground === 'Cream' &&
    css`
      background: ${props.theme.colours.cream};
    `}

  ${props =>
    props.iconBackground === 'Green' &&
    css`
      background: ${props.theme.colours.lightGreen};
    `}

  //Columns
  ${props =>
    props.columns === 6 &&
    css`
      height: 130px;

      @media (max-width: ${props.theme.breakpoints.max.xl}) {
        height: 110px;
      }

      @media (max-width: 1050px) {
        height: 130px;
      }

      @media (max-width: ${props.theme.breakpoints.max.lg}) {
        height: 110px;
      }

      @media (max-width: 850px) {
        height: 140px;
      }

      @media (max-width: 690px) {
        height: 25vw;
      }

      @media (max-width: ${props.theme.breakpoints.max.xs}) {
        height: 40vw;
      }
    `}

  ${props =>
    props.columns === 5 &&
    css`
      height: 150px;

      @media (max-width: ${props.theme.breakpoints.max.xl}) {
        height: 130px;
      }

      @media (max-width: ${props.theme.breakpoints.max.lg}) {
        height: 140px;
      }

      @media (max-width: ${props.theme.breakpoints.max.md}) {
        height: 25vw;
      }

      @media (max-width: ${props.theme.breakpoints.max.sm}) {
        height: 30vw;
      }

      @media (max-width: ${props.theme.breakpoints.max.xs}) {
        height: 35vw;
      }
    `}

  ${props =>
    props.columns === 4 &&
    css`
      height: 170px;

      @media (max-width: ${props.theme.breakpoints.max.lg}) {
        height: 220px;
      }

      @media (max-width: 850px) {
        height: 150px;
      }

      @media (max-width: 675px) {
        height: 30vw;
      }

      @media (max-width: ${props.theme.breakpoints.max.xs}) {
        height: 40vw;
      }
    `}

  ${props =>
    props.columns === 3 &&
    css`
    height: 230px;

    ${props.imageAs === 'Icon' &&
      css`
        @media (min-width: ${props.theme.breakpoints.min.xs}) {
          padding: 30px;
        }
      `}

    @media(max-width: ${props.theme.breakpoints.max.xl}){
      height: 260px;
    }

    @media(max-width: ${props.theme.breakpoints.max.lg}){
      height: 220px;
    }

    @media(max-width: 850px){
      height: 30vw;
    }

    @media(max-width: ${props.theme.breakpoints.max.md}){
      height: 35vw;
    }

    @media(max-width: ${props.theme.breakpoints.max.xs}){
      height: 40vw;
    }
    
  `}

  ${props =>
    props.columns === 2 &&
    css`
    height: 325px;
 
    ${props.imageAs === 'Icon' &&
      css`
        @media (min-width: ${props.theme.breakpoints.min.lg}) {
          padding: 40px;
        }

        @media (min-width: ${props.theme.breakpoints.min.xs}) {
          padding: 30px;
        }
      `}

    @media(max-width: ${props.theme.breakpoints.max.xl}){
      height: 260px;
    }

    @media(max-width: ${props.theme.breakpoints.max.lg}){
      height: 40vw;
    }
  `}
`;

const StyledHeading = styled.h3`
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 8px;
  color: ${props => props.theme.colours.black};
`;

const StyledShortDescription = styled.p`
  margin-bottom: 0;
  color: ${props => props.theme.colours.black};
`;

const StyledLongDescription = styled.div`
  margin-top: 10px;

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => props.theme.colours.black};
    font-family: ${props => props.theme.font.nimbleFont};
    font-style: normal;
    padding: 0;
    margin-bottom: 8px;
  }
  h2 {
    font-size: 29px;
    line-height: 32px;

    @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 7px;
    }
  }

  h3 {
    @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 100%;

  p {
    margin-top: 0;
  }

  ${props =>
    props.columns >= 5 &&
    css`
      ${StyledLongDescription}, ${StyledShortDescription} {
        font-size: 14px;
        line-height: 20px;
      }
    `}

  ${props =>
    props.columns <= 4 &&
    css`
      ${StyledHeading} {
        font-size: 20px;
        line-height: 24px;
      }

      ${StyledLongDescription}, ${StyledShortDescription} {
        @media (max-width: ${props.theme.breakpoints.max.sm}) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    `}
`;

const CardButton = styled.span`
  cursor: pointer;
  font-family: ${props => props.theme.font.nimbleFont};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 11px 40px;
  transition: 0.35s ease;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  text-align: center;
  min-width: unset;
  margin-top: auto;
  margin-left: auto;
  width: fit-content;
  box-sizing: border-box;
  background: ${props => props.theme.colours.green};
  color: ${props => props.theme.colours.white};
  border: 2px solid ${props => props.theme.colours.green};
  border-radius: 4px;

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    width: 100%;
  }

  &:hover {
    color: ${props => props.theme.colours.white};
    background-color: ${props => props.theme.colours.green};
    border-color: ${props => props.theme.colours.green};
  }
`;

export default function CardContent({
  columns,
  heading,
  shortDescription,
  longDescription,
  image,
  imageAs,
  iconBackground,
  linkText,
  displayButton
}) {
  return (
    <>
      {image && (
        <ImageContainer
          iconBackground={iconBackground}
          imageAs={imageAs}
          columns={columns}
        >
          <Image style={{ backgroundImage: `url(${image})` }} />
        </ImageContainer>
      )}
      <TextContainer columns={columns} className="cards-text-container">
        <ContentWrapper>
          {heading && (
            <StyledHeading className="cards-text-heading">
              {heading}
            </StyledHeading>
          )}
          {shortDescription && (
            <StyledShortDescription as={longDescription ? 'h4' : 'p'}>
              {shortDescription}
            </StyledShortDescription>
          )}
          {longDescription && (
            <StyledLongDescription>
              <RichText text={longDescription} />
            </StyledLongDescription>
          )}
        </ContentWrapper>
        {displayButton && linkText && <CardButton className='button'>{linkText}</CardButton>}
      </TextContainer>
    </>
  );
}

CardContent.propTypes = {
  columns: PropTypes.number,
  heading: PropTypes.string,
  shortDescription: PropTypes.string,
  longDescription: PropTypes.objectOf(PropTypes.string),
  image: PropTypes.string,
  imageAs: PropTypes.string,
  iconBackground: PropTypes.string,
  linkText: PropTypes.string,
  displayButton: PropTypes.boolean
};
