/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

const LogoContainer = styled.a`
  transition: 0.35s ease;

  &:hover {
    opacity: 0.75;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    display: none;
  }
`;

const StyledLogo = styled.img`
  width: 96px;
  height: 80px;
  margin-top: 60px;
`;

const StyledText = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin: 0 0 5px;

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    font-size: 30px;
    line-height: 35px;
  }
`;

const PhonenumberLink = styled.a`
  color: #ffffff;
  transition: 0.35s ease;

  &:hover {
    color: #26cfb6;
  }
`;

const StyledPhoneNumber = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-decoration: underline;
  margin: 4px 0;

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
  }
`;

const Logo = ({
  phoneNumber,
  phoneNumberText,
  footerLogo
}) => {
  return (
    <>
      <StyledText>Need help?</StyledText>
      <PhonenumberLink href={`tel:${phoneNumber}`}><StyledPhoneNumber>{phoneNumberText}</StyledPhoneNumber></PhonenumberLink>
      <LogoContainer href="/">
        <StyledLogo src={footerLogo.file.url} alt="nimble logo" />
      </LogoContainer>
    </>
  );
};

export default Logo;
