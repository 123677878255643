import { createGlobalStyle } from 'styled-components';
import { theme } from './theme-vars';

// Assets
import SharpGroteskBold from '../../static/fonts/SharpGroteskBold15-Regular.woff2';
import SharpGroteskBoldItalic from '../../static/fonts/SharpGroteskBoldItalic15-Regular.woff2';
import SharpGroteskBook from '../../static/fonts/SharpGroteskBook20-Regular.woff2';
import SharpGroteskBookItalic from '../../static/fonts/SharpGroteskBookItalic20-Regular.woff2';
import SharpGroteskSmBold from '../../static/fonts/SharpGroteskSmBold20-Regular.woff2';

import SharpGroteskBoldWoff from '../../static/fonts/SharpGroteskBold15-Regular.woff';
import SharpGroteskBoldItalicWoff from '../../static/fonts/SharpGroteskBoldItalic15-Regular.woff';
import SharpGroteskBookWoff from '../../static/fonts/SharpGroteskBook20-Regular.woff';
import SharpGroteskBookItalicWoff from '../../static/fonts/SharpGroteskBookItalic20-Regular.woff';
import SharpGroteskSmBoldWoff from '../../static/fonts/SharpGroteskSmBold20-Regular.woff';

const GlobalStyle = createGlobalStyle`

    /* Basic version of each font, before variants */
    @font-face {
        font-family: "SharpGroteskBold";
        src: url(${SharpGroteskBold}) format('woff2'),
        url(${SharpGroteskBoldWoff}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: "SharpGroteskBook";
        src: url(${SharpGroteskBook}) format('woff2'),
        url(${SharpGroteskBookWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: "SharpGroteskSmBold";
        src: url(${SharpGroteskSmBold}) format('woff2'),
        url(${SharpGroteskSmBoldWoff}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: "SharpGroteskBookItalic";
        src: url(${SharpGroteskBookItalic}) format('woff2'),
        url(${SharpGroteskBookItalicWoff}) format('woff');
        font-weight: normal;
        font-style: oblique;
        font-display: swap;
    }
    
    @font-face {
        font-family: "SharpGroteskBoldItalic";
        src: url(${SharpGroteskBoldItalic}) format('woff2'),
        url(${SharpGroteskBoldItalicWoff}) format('woff');
        font-weight: bold;
        font-style: oblique;
        font-display: swap;
    }

    @font-face {
        font-family: 'Material Icons';
        font-style: normal;
        font-weight: 400;
        font-display: block;
        src: url(https://fonts.gstatic.com/s/materialicons/v85/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
    }

    html, body {
        position: relative;
        overflow-x: hidden; 
        margin: 0;
        padding: 0;
        font-family: ${theme.font.nimbleFont};
        color: ${theme.colours.textColor};
        height: auto;
        font-size: 16px;
        scroll-behavior: smooth;
        box-sizing: border-box;
        background-color: white;
    }

    body {
        box-sizing: border-box;
        width: 100%;    
        box-shadow: 0 0 10px 2px #dedddd;
        display: block;
        animation: none !important;
        text-rendering: geometricPrecision;

        @media(min-width: 2000px){
            max-width: 1980px;
            margin: auto;
        }

        @media print {
            box-shadow: none;
        }
    }

    header .container {
        background: ${theme.colours.white};
    }

    div {
        box-sizing: border-box;
    }

    :link, :visited, ins {
    }

    nav ul, nav ol {
        list-style: none
    }
    b, strong {
        font-family: ${theme.font.nimbleBoldFont};
    }

    dl, ul, ol, li,
    h1, h2, h3, h4, h5, h6,
    pre, p, blockquote,
    form, fieldset, input, label {
        margin: 0;
        padding: 0
    }

    abbr, img, object,
    a img, :link img, :visited img,
    a object, :link object, :visited object {
        border: 0
    }

    address, abbr {
        font-style: normal
    }

    .clear {
        clear: both;
    }

    h5, h6 {
        font-family: ${theme.font.nimbleFont};
        color: ${theme.colours.orange};
        height: auto;
        font-size: 16px;
        padding: 0 20px;
        max-width: 950px;
        margin: 0 auto
    }

    li p {
        margin-top: 0;
        margin-bottom: 0
    }
    /* iframe:not(.auto-link) { display:none ! important; visibility:hidden ! important; margin-left: -10000px ! important  } */
    .link {
        background: none;
        border: 0;
        cursor: pointer;
        margin: 0 !important;
        padding: 0 !important
    }

    a, .link {
        color: ${theme.colours.textColor};
        text-decoration: none;

        &:hover {
            color: ${theme.colours.orange};
        }
    }

    p a, p .link, li a {
        color: ${theme.colours.orange};

        &:hover {
            color: ${theme.colours.textColor};
        }

        white-space: normal;
    }

    button, a, .link {
        outline: 0;
        -webkit-tap-highlight-color: transparent;
        text-rendering: geometricPrecision;

        &:focus {
            outline: 0;
        }

        &:active {
            outline: 0;
        }
    }

    a:hover,
    a:focus,
    .link:hover,
    .link:focus {
        text-decoration: none;
    }

    section {
        position: relative;

        .content {
            margin-bottom: 20px
        }

        &.grey {
            background-color: ${theme.colours.grey};
        }

        &.orange {
            background-color: ${theme.colours.orange};
        }

        &.pink {
            background-color: ${theme.colours.pink};
        }

        &.white {
            background-color: ${theme.colours.white};
        }
    }


    h1, .h1 {
        font-family: ${props => props.theme.font.nimbleFont};
        font-size: 42px;
        line-height: 48px;
        font-weight: 600;
        font-style: normal;

        @media(max-width: ${props => props.theme.breakpoints.max.xl}) {
            font-size: 35px;
            line-height: 42px;
        }

        @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
            font-size: 32px;
        }
    }

    .landing .grid-item h1 {
        font-size: 75px;
        line-height: 84px;

        @media (max-width:1470px) {
            font-size: 60px;
            line-height: 64px;
        }

        @media (max-width:768px) {
            font-size: 50px;
            line-height: 54px;
        }

        @media (max-width:460px) {
            font-size: 36px;
            line-height: 32px;
        }
    }

    h2, .h2, .h2-style, .h2-style-phone {
        font-family: ${props => props.theme.font.nimbleFont};
        font-style: normal;
        font-size: 29px;
        line-height: 32px;
        font-weight: 600;

        @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 7px;
        }  
    }

    p ~ h2 {
        margin-top: 40px;
    }

    .h2-style, .h2-style-phone,
    .h3-style, .h3-style-phone {
        margin: 0;
    }

    h3, .h3, .h3-style, .h3-style-phone {
        color: ${theme.colours.orange};
        font-family: ${props => props.theme.font.nimbleFont};
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 0;

        @media(max-width: ${props => props.theme.breakpoints.max.sm}){
            font-size: 16px;
            line-height: 20px;
        }
    }

    h4, .h4 {
        color: ${theme.colours.orange};
        font-family: ${theme.font.nimbleFont};
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
    }

    p, .link {
        font-family: ${theme.font.nimbleFont};
        font-weight: normal;
        line-height: 1.9;
        margin: 20px 0;
    }

    li {
        font-family: ${theme.font.nimbleFont};
        font-size: 16px;
        font-weight: normal;
        line-height: 2;
        padding-left: calc(1.5em + 0px);
        margin-left: 2em;
    }

    .small {
        font-size: 12px;
    }

    .small, .small p, small, small p {
        font-size: smaller;
    }

    .pt-10 {
        padding-top: 10px;
    }

    .m-10 {
        margin: 10px;
    }

    .mt-0 {
        margin-top: 0 !important;
    }

    .mb-0 {
        margin-top: 0 !important;
    }

    .mt-10 {
        margin-top: 10px;
    }

    .mb-10 {
        margin-bottom: 10px;
    }
    
    .ml-10 {
        margin-left: 10px;
    }
    
    .mr-10 {
        margin-right: 10px;
    }
    
    .mb-15 {
        margin-bottom: 15px;
    }

    .m-20 {
        margin: 20px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    .mb-20 {
        margin-bottom: 20px;
    }

    .ml-20 {
        margin-left: 20px;
    }

    .mr-20 {
        margin-right: 20px;
    }

    .m-30 {
        margin: 30px;
    }

    .mt-30 {
        margin-top: 30px;
    }

    .mb-30 {
        margin-bottom: 30px;
    }

    .ml-30 {
        margin-left: 30px;
    }

    .mr-30 {
        margin-right: 30px;
    }

    .m-40 {
        margin: 40px;
    }

    .mt-40 {
        margin-top: 40px;
    }

    .mb-40 {
        margin-bottom: 40px;
    }

    .ml-40 {
        margin-left: 40px;
    }

    .mr-40 {
        margin-right: 40px;
    }

    .m-50 {
        margin: 50px;
    }

    .mt-50 {
        margin-top: 50px;
    }

    .mb-50 {
        margin-bottom: 50px;
    }

    .ml-50 {
        margin-left: 50px;
    }

    .mr-50 {
        margin-right: 50px;
    }

    .p-5 {
        padding: 5px;
    }

    .pt-5 {
        padding-top: 5px;
    }

    .pb-5 {
        padding-bottom: 5px;
    }

    .pl-5 {
        padding-left: 5px;
    }

    .pr-5 {
        padding-right: 5px;
    }

    .p-10 {
        padding: 10px;
    }

    .pt-10 {
        padding-top: 10px;
    }

    .pb-10 {
        padding-bottom: 10px;
    }

    .pl-10 {
        padding-left: 10px;
    }

    .pr-10 {
        padding-right: 10px;
    }

    .p-20 {
        padding: 20px;
    }

    .pt-20 {
        padding-top: 20px;
    }

    .pb-20 {
        padding-bottom: 20px;
    }

    .pl-20 {
        padding-left: 20px;
    }

    .pr-20 {
        padding-right: 20px;
    }

    .p-30 {
        padding: 30px;
    }

    .pt-30 {
        padding-top: 30px;
    }

    .pb-30 {
        padding-bottom: 30px;
    }

    .pl-30 {
        padding-left: 30px;
    }

    .pr-30 {
        padding-right: 30px;
    }

    .p-40 {
        padding: 40px;
    }

    .pt-40 {
        padding-top: 40px;
    }

    .pb-40 {
        padding-bottom: 40px;
    }

    .pl-40 {
        padding-left: 40px;
    }

    .pr-40 {
        padding-right: 40px;
    }

    .p-100 {
        padding: 100px;
    }

    .pt-100 {
        padding-top: 100px;
    }

    .pb-100 {
        padding-bottom: 100px;
    }

    .pl-100 {
        padding-left: 100px;
    }

    .pr-100 {
        padding-right: 100px;
    }

    .underline {
        text-decoration: underline;
    }

    .border {
        border: 1px solid #cecece;
    }

    .button {
        cursor: pointer;
        font-family: ${props => props.theme.font.nimbleFont};
        border-radius: ${theme.misc.buttonRadius};
        padding: 12px 20px;
        font-size: 16px;
        line-height: 28px;
        font-weight: 600;
        min-width: 180px;
        display: inline-block;
        position: relative;
        text-align: center;
        transition: 0.35s ease;

        &.btn-red {
            padding: 10px 25px;
            text-transform: none;
            background-color: ${theme.colours.orange};
            color: white;
            border: 1px solid ${theme.colours.white};
            font: inherit;

            &:hover {
                background: ${theme.colours.white};
                color: ${theme.colours.orange};
            }
        }

        &.btn-orange {
            background: ${theme.colours.orange};
            color: ${theme.colours.white};
            border: 2px solid ${theme.colours.orange};

            &:hover {
                background: ${theme.colours.white};
                color: ${theme.colours.orange};
            }
        }

        &.btn-grey {
            background: ${theme.colours.grey};
            color: ${theme.colours.textColor};
            border: 2px solid ${theme.colours.grey};

            &:hover {
                background: ${theme.colours.textColor};
                color: ${theme.colours.orange};
                border: 2px solid ${theme.colours.orange};
            }
        }

        &.btn-yellow {
            background: ${theme.colours.yellow};
            color: ${theme.colours.white};
            border: 2px solid ${theme.colours.yellow};
            border-radius: ${theme.misc.ButtonRadius};

            &:hover {
                background: ${theme.colours.white};
                color: ${theme.colours.yellow};
            }

            i {
                background-color: rgba(0,0,0,.08);
            }
        }

        &.btn-green {
            background: ${theme.colours.green};
            color: ${theme.colours.white};
            border: 2px solid ${theme.colours.green};
            border-radius: ${theme.misc.ButtonRadius};

            &:hover {
                background: ${theme.colours.white};
                color: ${theme.colours.green};
            }
        }

        &.btn-white {
            background: ${theme.colours.white};
            color: ${theme.colours.orange};
            border: 2px solid ${theme.colours.white};
            border-radius: ${theme.misc.ButtonRadius};

            &:hover {
                background: ${theme.colours.orange};
                color: ${theme.colours.white};
            }
        }

        &.btn-link {
            padding: 0;
            color: ${theme.colours.orange};

            &:hover {
                color: #F57B69
            }

            &.btn-outline {
                padding: 8px 11px;
            }
        }

        &.btn-outline {
            border: 2px solid ${theme.colours.orange};
        }

        &.yellow {
            color: ${theme.colours.yellow}
        }
    }

    .white, section > h1 {
        color: ${theme.colours.white};
    }

    .container {
        width: 100%;
        transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        padding-right: 0;
        padding-left: 0;
        margin-right: auto;
        margin-left: auto;
    }

    .grid-container {
        width: 100%;
        max-width: 1440px;
        transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        margin-right: auto;
        margin-left: auto;

        @media only screen and (max-width: 1520px) {
            margin-left: ${theme.sectionPadding.desktopPaddingLr};
            margin-right: ${theme.sectionPadding.desktopPaddingLr};
            width: calc(100% - #{${theme.sectionPadding.desktopPaddingLr}*2});
        }

        @media only screen and (max-width: ${theme.screens.screenLarge}) {
            margin-left: ${theme.sectionPadding.mobilePaddingLr};
            margin-right: ${theme.sectionPadding.mobilePaddingLr};
            width: calc(100% - #{${theme.sectionPadding.mobilePaddingLr}*2});
        }
    }

    .grid-container-small {
        width: 100%;
        max-width: 950px;
        transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        margin-right: auto;
        margin-left: auto;

        @media only screen and (max-width: 1030px) {
            margin-left: ${theme.sectionPadding.desktopPaddingLr};
            margin-right: ${theme.sectionPadding.desktopPaddingLr};
            width: calc(100% - #{${theme.sectionPadding.desktopPaddingLr}*2});
        }

        @media only screen and (max-width: ${theme.screens.screenLarge}) {
            margin-left: ${theme.sectionPadding.mobilePaddingLr};
            margin-right: ${theme.sectionPadding.mobilePaddingLr};
            width: calc(100% - #{${theme.sectionPadding.mobilePaddingLr}*2});
        }

        &.tiny {
            max-width: 500px;
            margin-right: auto;
            margin-left: auto;
        }
    }

    .grid-item-small {
        @media (min-width:${theme.screens.screenLarge}) {
            max-width: 36%;
        }
    }

    .card {
        background-color: ${theme.colours.white};
        border-radius: 10px;
        box-shadow: 0 5px 15px rgba(0,0,0,.08);
        height: 100%;

        &.noshadow {
            box-shadow: none;
        }

        &:not(.content) {
            padding: 30px;
        }
    }

    .center {
        text-align: center;
    }

    .right {
        text-align: right;
    }

    .left {
        text-align: left;
    }

    .section-padding-top {
        padding-top: ${theme.sectionPadding.sectionPaddingTbDesktop} !important;

        @media only screen and (max-width: 767px) {
            padding-top: ${theme.sectionPadding.sectionPaddingTbMobile} !important;
        }
    }

    .section-padding-bottom {
        padding-bottom: ${theme.sectionPadding.sectionPaddingTbDesktop} !important;

        @media only screen and (max-width: 767px) {
            padding-bottom: ${theme.sectionPadding.sectionPaddingTbMobile} !important;
        }
    }

    .section-padding-top-half {
        padding-top: ${theme.sectionPadding.sectionPaddingTbDesktop} / 2;

        @media only screen and (max-width: 767px) {
            padding-top: ${theme.sectionPadding.sectionPaddingTbMobile} / 2;
        }
    }

    .orange {
        color: ${theme.colours.orange};
    }


    /* RE-MADE UI */

    .grid {
        display: flex;

        &.a-center {
            align-items: center;
        }

        &.j-center {
            justify-content: center;
        }

        &.space {
            justify-content: space-between;
        }

        > * {
            flex: 1;

            &.f4 {
                flex: 4;
            }

            &.f3 {
                flex: 3;
            }

            &.f2 {
                flex: 2;
            }
        }

        &.shrink {
            * {
                flex: 0 1 auto;
            }
        }

        &.items-1-m {
            flex-wrap: wrap;

            > *:not(:first-child) {
                @media only screen and (max-width: ${theme.screens.screenLarge}) {
                    padding-left: 0px;
                }
            }

            > * {
                @media only screen and (max-width: ${theme.screens.screenLarge}) {
                    /* medium */
                    margin-top: 20px;
                    width: 100%;
                    flex: none;
                }
            }
        }

        &.items-1-s {
            flex-wrap: wrap;

            > *:not(:first-child) {
                @media only screen and (max-width: ${theme.screens.screenMedium}) {
                    padding-left: 0px;
                }
            }

            > * {
                @media only screen and (max-width: ${theme.screens.screenMedium}) {
                    /* small */
                    margin-top: 20px;
                    width: 100%;
                    flex: none;
                }
            }
        }
    }

    .left-l {
        @media only screen and (min-width: ${theme.screens.screenLarge}) {
            text-align: left;
        }
    }

    .right-m {
        @media only screen and (min-width: ${theme.screens.screenMedium}) {
            text-align: right;
        }
    }

    .left-m {
        @media only screen and (min-width: ${theme.screens.screenMedium}) {
            text-align: left;
        }
    }

    .hidden {
        display: none;
    }

    .hidden-m {
        @media only screen and (max-width: 959px) {
            display: none;
        }
    }

    .hidden-d {
        display: initial;

        @media only screen and (min-width: 959px) {
            display: none !important;
        }
    }

    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible;
        text-align: inherit;
        margin: 0 0 20px 0;
        border: 0;
        border-top: 1px solid #e5e5e5;
    }

    .slider {
        .slider-wrapper {
            padding: 70px 40px 30px;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            position: relative;
        }

        .slider-label {
            flex: 0 1;
            color: ${theme.colours.white};
            font-weight: bold
        }

        .noUi-tooltip {
            font-weight: bold
        }

        .slider-container {
            flex: 1;
            margin: 0 20px;
        }

        .container-bg {
            padding: 30px 70px;
            border-radius: 40px;
            height: 15px;
        }

        @media only screen and (max-width:650px) {
            .slider-container {
                margin: 0;
                min-width: 100%;
            }

            .slider-label {
                position: absolute;
                top: 90px;
                font-size: small;
            }

            .slider-wrapper .slider-label:last-child {
                right: 40px;
                padding-bottom: 70px
            }
        }
    }

    .noselect {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    }

    .bg-red {
        background-color: ${theme.colours.red}
    }

    .b-layout {
        padding: 0 20px;
    }

    .component-background_Red {
      background-color: ${theme.colours.orange};

      h1,
      h2,
      h3,
      h4,
      h5,
      p,
      h6,
      li {
        color: ${theme.colours.white};
      }

      a {
        color: ${theme.colours.yellow};
      }
    }

    .component-background_Green {
      background-color: ${theme.colours.green};

      h1,
      h2,
      h3 {
        color: ${theme.colours.yellow};
      }

      h4,
      h5,
      p,
      h6,
      li {
        color: ${theme.colours.white};
      }

      a {
        color: ${theme.colours.yellow};
      }

      a.component-button {
        font-family: ${theme.font.nimbleFont};
        font-weight: bold;
        background-color: ${theme.colours.black};
        color: ${theme.colours.white};
        border: solid 1px ${theme.colours.black};
        border-radius: 4px;

        &:hover {
          background-color: ${theme.colours.white};
          color: ${theme.colours.black};
        }
      }
    }

    .component-background_White {
      background-color: ${theme.colours.white};

      h1,
      h2,
      h3,
      h4,
      h5,
      .h2-style {
        color: ${theme.colours.orange};
      }

      p,
      h6,
      li {
        color: ${theme.colours.black};
      }

      a {
        color: ${theme.colours.orange};
        transition: 0.35s ease;
        &:hover{
            color: #a32926;
        }
      }
    }

    .component-background_Pink {
      background-color: ${theme.colours.pink};

      h1,
      h2,
      h3,
      h4,
      h5,
      .h2-style {
        color: ${theme.colours.white};
      }

      p,
      li {
        color: ${theme.colours.black};
      }

      small {
        color: ${theme.colours.white};
      }

      h6 {
        color: ${theme.colours.white};
      }

      a {
        color: ${theme.colours.orange};
      }

      .component-carousel .slick-dots li button {
        border-color: ${theme.colours.orange};
      }
    }

    .component-background_Grey {
      background-color: ${theme.colours.grey};

      h1,
      h2,
      h3,
      h4,
      h5 {
        color: ${theme.colours.orange};
      }

      p,
      h6,
      li {
        color: ${theme.colours.black};
      }

      a {
        color: ${theme.colours.orange};
      }
    }
    p.homepage-h1-style {
        font-family: "SharpGroteskBold", sans-serif;
        font-size: 86px;
        line-height: 1;
        font-weight: bold;
        color: #FFF;
        text-align: center;
        margin: 0px 0px 20px 0px!important;

     @media (max-width: 800px) {
    text-align: left;
        padding-left: 15px;
        font-size: 60px;
        width: 60%;
        padding-top: 30px;
        padding-bottom: 10px;
        margin: 0;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0) 100%);
    }
 }
 .ta-center {
     text-align: center;
 }

 
 
/* COMPONENTS PAGE STYLES */
 #components-page {
    margin: 0;
    padding: 0;
    background-color: ${theme.colours.white};

    .component {
        box-sizing: border-box;
        overflow: auto;
        * {
            box-sizing: border-box;
        }

        > * {
            margin-top: 10px;
        }

        &:first-child {
            > * {
                margin-top: 0;
            }
        }

        &.Padding-Top-None {
            > *:first-child {
                margin-top: 0 !important;
            }
        }
        &.Padding-Bottom-None + .component {
            > * {
                margin-top: 0px !important;
            }
        }

        .component-background_Red {
            background-color: ${theme.colours.orange};

            h1, h2, h3, h4, h5, p, h6, li {
                color: ${theme.colours.white};
            }

            a {
                color: ${theme.colours.yellow};
            }

            .component-content {
                form {
                    label {
                        color: ${theme.colours.white};
                    }
                }
            }

            .slick-dots {
                li {
                    button {
                        border-color: ${theme.colours.white};
                    }

                    &.slick-active {
                        button {
                            background-color: ${theme.colours.white};
                        }
                    }
                }
            }

            .tooltip-icon {

                &:hover {
                    color: ${theme.colours.yellow};
                }
            }
        }

        .component-background_Green {
            background-color: ${theme.colours.green};

            h1, h2, h3 {
                color: ${theme.colours.yellow};
            }

            h4, h5, p, h6, li {
                color: ${theme.colours.white};
            }

            a {
                color: ${theme.colours.yellow};
            }

            a.component-button {
                font-family: ${theme.font.nimbleFont};
                font-weight: bold;
                background-color: ${theme.colours.black};
                color: ${theme.colours.white};
                border: solid 1px ${theme.colours.black};
                border-radius: 4px;

                &:hover {
                    background-color: ${theme.colours.white};
                    color: ${theme.colours.black};
                }
            }

            .component-content {
                form {
                    label {
                        color: ${theme.colours.white};
                    }
                }
            }

            .slick-dots {
                li {
                    button {
                        border-color: ${theme.colours.white};
                    }

                    &.slick-active {
                        button {
                            background-color: ${theme.colours.white};
                        }
                    }
                }
            }
        }

        .component-background_White {
            background-color: ${theme.colours.white};

            h1, h2, h3, h4, h5, .h2-style {
                color: ${theme.colours.orange};
            }

            p, h6, li {
                color: ${theme.colours.black};
            }

            a {
                color: ${theme.colours.orange};
                transition: 0.35s ease;
                &:hover{
                    color: #a32926;
                }
            }
        }

        .component-background_Pink {
            background-color: ${theme.colours.pink};

            h1, h2, h3, h4, h5, .h2-style {
                color: ${theme.colours.white};
            }

            p, li {
                color: ${theme.colours.black};
            }

            small {
                color: ${theme.colours.white};
            }

            h6 {
                color: ${theme.colours.white};
            }

            a {
                color: ${theme.colours.orange};
            }

            .component-carousel .slick-dots li button {
                border-color: ${theme.colours.orange};
            }
        }

        .component-background_Grey {
            background-color: ${theme.colours.grey};

            h1, h2, h3, h4, h5 {
                color: ${theme.colours.orange};
            }

            p, h6, li {
                color: ${theme.colours.black};
            }

            a {
                color: ${theme.colours.orange};
            }
        }
    }

    .tooltip-icon {
        vertical-align: text-bottom;
        cursor: pointer;

        &:hover {
            color: ${theme.colours.orange};
        }
    }
    a.component-button, input.component-button {
        font-family: ${theme.font.nimbleFont};
        font-weight: bold;
        display: inline-block;
        font-size: 16px;
        padding: 15px 30px;
        border: solid 2px ${theme.colours.green};
        background-color: ${theme.colours.green};
        color: ${theme.colours.white};
        transition-duration: 0.2s;
        text-align: center;
        border-radius: ${theme.misc.buttonRadius};
        margin-bottom: 20px;
        border-radius: 4px;

        @media(max-width: 500px) {
            width: 90%;
            margin: 0 0 20px;
        }

        &:hover {
            background-color: ${theme.colours.white};
            color: ${theme.colours.green};
            cursor: pointer;
        }
        &.button-color_Green, &.button-color_Green-Transparent {
            border-color: ${theme.colours.green};
            background-color: ${theme.colours.green};
            color: ${theme.colours.white};

            &:hover {
                background-color: ${theme.colours.white};
                color: ${theme.colours.green};
            }

            &.button-color_Green-Transparent {
                background-color: transparent;

                &:hover {
                    background-color: ${theme.colours.white};
                }
            }
        }

        &.button-color_Red, &.button-color_Red-Transparent {
            border: solid 2px ${theme.colours.orange};
            background-color: ${theme.colours.orange};
            color: ${theme.colours.white};

            &:hover {
                background-color: ${theme.colours.white};
                color: ${theme.colours.orange};
            }

            &.button-color_Red-Transparent {
                background-color: transparent;

                &:hover {
                    background-color: ${theme.colours.white};
                }
            }
        }

        &.button-color_White, &.button-color_White-Transparent {
            border: solid 2px ${theme.colours.white};
            background-color: ${theme.colours.white};
            color: ${theme.colours.orange};

            &:hover {
                background-color: ${theme.colours.orange};
                color: ${theme.colours.white};
            }

            &.button-color_White-Transparent {
                background-color: transparent;

                &:hover {
                    background-color: ${theme.colours.orange};
                }
            }
        }

        &.button-color_Yellow, &.button-color_Yellow-Transparent {
            border: solid 2px ${theme.colours.yellow};
            background-color: ${theme.colours.yellow};
            color: ${theme.colours.black};

            &:hover {
                background-color: ${theme.colours.white};
                color: ${theme.colours.black};
            }

            &.button-color_Yellow-Transparent {
                background-color: transparent;
                color: ${theme.colours.white};

                &:hover {
                    background-color: ${theme.colours.white};
                    color: ${theme.colours.black};
                }
            }
        }
    }
    .component-carousel {
        width: 100%;
        max-width: ${theme.components.maxWidth};
        margin-left: auto;
        margin-right: auto;

        .slick-list {
            padding-top: 25px;
        }

        .slick-dots {
            background-color: transparent;
            left: 0;

            @media(min-width:${theme.components.desktopWidthMin}) {
                bottom: -50px;
            }

            li {
                button {
                    border-radius: 50%;
                    width: ${theme.components.dotSize};
                    height: ${theme.components.dotSize};
                    border: solid 2px ${theme.colours.pink};
                }

                &.slick-active {
                    button {
                        background-color: ${theme.colours.orange};
                        border-color: ${theme.colours.orange};
                    }
                }
            }
        }
    }

    .component-background_Red {
        .testimonails-content {
            .testimonials-carousel {
                .slick-dots {
                    li {
                        button {
                            border-color: ${theme.colours.white};
                        }

                        &.slick-active {
                            button {
                                background-color: ${theme.colours.white};
                            }
                        }
                    }
                }
            }
        }
    }


    .slick-slide {
        outline: none !important;
    }

    .slick-dots {
        background-color: transparent;
        left: 0;

        li {
            button {
                border-radius: 50%;
                width: ${theme.components.dotSize};
                height: ${theme.components.dotSize};
                border: solid 1px ${theme.colours.orange};

                &:before {
                    display: none;
                }
            }

            &:only-child {
                display: none;
            }

            &.slick-active {
                button {
                    background-color: ${theme.colours.orange};
                }
            }
        }
    }

    h1 {
        &:empty {
            display: none;
        }
    }

    h2, .h2-style {

        &:empty {
            display: none;
        }
    }
    .component-text {

        &:empty {
            display: none;
        }

        h3 {
            padding-top: 10px;
            margin-bottom: 20px;
        }

        h4 {
            margin-bottom: 20px;
        }
        h5 {
            color: inherit;
            font-weight: normal;
            text-align: center;
            margin-bottom: 20px;
        }
        h6 {
            color: black;
            font-size: 12px;
            font-weight: normal;
            margin-bottom: 14px;

            a {
                color: ${theme.colours.orange};
            }
        }
        > ul, > ol {
            > li {
                padding-left: 0;
                margin-left: 3rem;
                line-height: 1.25;
                margin-bottom: 10px;

                @media(max-width: ${theme.components.phoneWidthMax}) {
                    margin-left: 1.5rem;
                }

                > p {
                    padding-left: 1rem;
                }
            }
        }

        p {
        }

        table {
            tr {
                height: auto !important;

                th, td {
                    height: auto !important;
                    vertical-align: middle;
                }
            }
        }
        a[href='#read-more'] {
            font-weight: bold;
        }

        blockquote {
            &.read-more-hidden {
                display: none;
            }
        }
    }

    .height-equalizer {
        .height-measure {
            overflow: hidden;
        }
    }

    .component-content {

        .form-fields {
            overflow: auto;

            input, select, textarea {
                height: auto;
            }

            div {
                width: 100%;
                padding: 11px 5% 5px;

                span {
                    font-weight: bold;
                }
            }

            &.two-col {
                @media only screen and (min-width: 768px) {
                    width: 50%;
                    padding: 5px 10px;
                    float: left;
                    text-align: left;
                }

            }
        }


        .submit-success {
            display: none;
            text-align: center;
            font-weight: bold;
            margin-top: 20px;
        }

        .submit-error {
            display: none;
            text-align: center;
            background-color: red;
            padding: 10px;
            margin-top: 20px;
            color: ${theme.colours.white};
            border: solid 1px ${theme.colours.white};
        }
    }
}
.stepper {
    p {
        color: #FFFFFF;
        font-size: 24px;
        text-align: center;
        padding: 0 10px;
        font-family: ${theme.font.nimbleFont};
        font-weight: bold;
    }
}

/* SEO STYLES */
h4.h3-style p {
color: #FFFFFF!important;
font-size: 18px!important;
line-height: 1.6;
margin-bottom: 0;
padding: 0px 20px;
 }

 p.homepage-h1-style {
font-family: ${theme.font.nimbleHeadingFont}, sans-serif;
    font-size: 86px;
    line-height: 1;
    font-weight: bold;
color: #FFF;
text-align: center;
    margin: 0px 0px 20px 0px!important;

     @media (max-width: 800px) {
    text-align: left;
    padding-left: 15px;
    font-size: 60px;
    width: 60%;
    padding-top: 30px;
    padding-bottom: 10px;
    margin: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0) 100%);
}
}

 #read-more, #read-more-car-loans, #read-more-personal-loans {
display:none;

a {
    color:#ED2924!important;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;

    &:hover {
        color:#ED2924!important;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }
}

h1 {
    color: #000!important;
    font-size: 52px!important;
}

h2 {
    text-align: left!important;
    font-size: 38px!important;
    font-style: normal!important;
}
}

 #read-more-car-loans, #read-more-personal-loans {
    margin: 0px 5%;

    h2 {
        margin-bottom: 0px!important;
        padding-bottom: 5px!important;
    }

    li {
        font-size: 14px;
    }

    ul {
    margin-bottom: 10px;
    }
}

#read-more-homepage {
display: none;
padding: 0px 25px 30px;

a {
    color:#ED2924!important;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;

    &:hover {
        color:#ED2924!important;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }
}

h1 {
    font-size: 52px!important;
}

h2 {
    text-align: left!important;
    font-size: 38px!important;
    font-style: normal!important;
}

@media (max-width: 800px) {
    padding: 0px 0px 30px;
}
}

#show-more, a#hide-less, #show-more-car-loans, a#hide-less-car-loans, #show-more-homepage, a#hide-less-homepage,  #show-more-personal-loans, a#hide-less-personal-loans {
color:#ED2924!important;
}

a#show-more-homepage, a#hide-less-homepage, a#show-more-car-loans, a#hide-less-car-loans, a#show-more-personal-loans, a#hide-less-personal-loans {
font-weight: bold;
}

.component-content .expanded-content {
    
h1, h2, h3, h4, p {
    color: #1e1e1e!important;
}

li {
    color: #1e1e1e!important;
    font-size: 14px;
}
}
 .Padding-Top-None {
    > *:first-child {
        margin-top: 0 !important;
    }
}
.component-content {
    max-width: 1440px;
}
.section-wrapper {
    padding: 65px 30px;
}
.primary-btn {
    border: none;
    padding: 15px 30px;
    font-family: ${theme.font.nimbleFont};
    font-weight: bold;
    font-size: 20px;
    border-radius: 5px;

    @media (max-width: ${theme.components.phoneWidthMax}) {
      width: 100%;
    }

    &--red {
      background-color: ${theme.colours.red};
      border: 2px ${theme.colours.red} solid;
      color: ${theme.colours.white};
      cursor: pointer;
      transition: background-color 100ms linear;
      transition: color 100ms linear;
      &:hover {
        background-color: ${theme.colours.white};
        color: ${theme.colours.red};
      }
    }
  }

  .nimble-tooltip {
    display: inline-block;
    vertical-align: top;
    line-height: 10px;
  }
`;

export default GlobalStyle;
