import React from 'react';
import loadable from '@loadable/component';

const Components = ({ pageData, components }) => {
  const heroTextBottom = pageData
    ? JSON.parse(pageData.internal.content).heroTextBottom
    : null;

  return (
    <div id="components-page">
      {components.map((data, index) => {
        const { __typename: componentType } = data;
        // Iterate over components and render based on types
        switch (componentType) {
          case 'ContentfulComponentTestimonials':
            const TestimonialsComponent = loadable(() => import(`../testimonials`));
            return <TestimonialsComponent key={`${componentType}_${index}`} data={data} />;

          case 'ContentfulNimbleRepaymentCalculatorComplex':
            const RepaymentCalculatorComplex = loadable(() => import(`../repaymentCalculatorComplex`));
            return <RepaymentCalculatorComplex key={`${componentType}_${index}`} data={data} />;

          case 'ContentfulComponentFaq':
            const FAQComponent = loadable(() => import(`../faq`));
            return <FAQComponent key={`${componentType}_${index}`} data={data} />;

          case 'ContentfulComponentCards':
            const CardsComponent = loadable(() => import(`../cards`));
            return <CardsComponent key={`${componentType}_${index}`} data={data} />;

          case 'ContentfulComponentProofPoints':
            const ProofPointsComponent = loadable(() => import(`../proofPoints`));
            return <ProofPointsComponent key={`${componentType}_${index}`} data={data} />;

          case 'ContentfulComponentBasic':
            const BasicComponent = loadable(() => import(`../basic`));
            return <BasicComponent key={`${componentType}_${index}`} data={data} />;

          case 'ContentfulComponentBanner':
            const BannerComponent = loadable(() => import(`../banner`));
            return <BannerComponent key={`${componentType}_${index}`} data={data} />;

          case 'ContentfulNimbleHero':
            const HeroComponent = loadable(() => import(`../../components/hero`));
            return <HeroComponent key={`${componentType}_${index}`} heroTextBottom={heroTextBottom} data={data} />;

          case 'ContentfulComponentAccordionPanel':
            const AccordionPanelComponent = loadable(() => import(`../accordion`));
            return <AccordionPanelComponent key={`${componentType}_${index}`} data={data} />;

          case 'ContentfulComponentButtons':
            const Buttons = loadable(() => import(`../buttons`));
            return <Buttons key={`${componentType}_${index}`} data={data} />;

          case 'ContentfulComponentSlideshow':
            const SlideshowComponent = loadable(() => import(`../slideshow`));
            return <SlideshowComponent key={`${componentType}_${index}`} data={data} />;

          case 'ContentfulComponentContactForm':
            const ContactForm = loadable(() => import(`../contactForm`));
            return <ContactForm key={`${componentType}_${index}`} data={data} />;

          case 'ContentfulComponentLoginForm':
            const LoginForm = loadable(() => import(`../loginForm`));
            return <LoginForm key={`${componentType}_${index}`} data={data} />;

          case 'ContentfulComponentForgotPasswordForm':
            const ForgotPasswordForm = loadable(() => import(`../forgotPasswordForm`));
            return <ForgotPasswordForm key={`${componentType}_${index}`} data={data} />;

          case 'ContentfulComponentSubnav':
            const Subnav = loadable(() => import(`../subnav`));
            return <Subnav key={`${componentType}_${index}`} data={data} />;

          default:
            return null;
        }
      })}
    </div>
  );
}

export default Components;
