import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import styled, { css } from 'styled-components';
import RichText from '../../elements/richText';
// props.theme doesn't work with render, so theme is imported
import { theme } from '../../theme/theme-vars';
// Assets
import infoIconGreen from '../../../static/images/info_outline_green.svg';
import infoIconWhite from '../../../static/images/info_outline_white.svg';
import crossIcon from '../../../static/images/cross-blk.png';

const ModalOverideStyles = styled.span`
  button {
    background: none !important;
    border: none !important;
    cursor: pointer;
    display: inline-block;

    &:hover {
      opacity: 0.7;
    }

    img {
      width: 15px;
      height: 15px;
    }
  }
`;

const TooltipIcon = styled.span`
  width: 15px;
  height: 15px;
  line-height: 15px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  ${props => props.colour === 'white' && css`
    background-image: url('${infoIconWhite}');
  `}

  ${props => props.colour === 'green' && css`
    background-image: url('${infoIconGreen}');
  `}
`;

const OverlayStyles = styled.span`
  @media(max-width: ${theme.breakpoints.max.md}){
    max-width: 86%;
  }
  
  @media(max-width: ${theme.breakpoints.max.xs}){
    max-width: 92%;
  }

  &:focus{
    outline: none;
  }

  .icon-wrapper {
    position: absolute !important;
    top: 0;
    right: 0;
    margin: 15px 10px 10px;

    .tooltip-button {
      background: none;
      border: none;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      .cross-icon {
        width: 15px;
        height: auto;
      }
    }
  }
`;

const TooltipButton = styled.button`
  padding: 0;
`;

const ModalContent = styled.span`
  display: block;
  position: relative;
  background-color: #ffffff;
  margin: 0 auto;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
  padding: 30px 30px 16px;
  width: 100%;
  max-width: 600px;
  border-radius: 6px;
  box-sizing: border-box;

  @media(max-height: 600px){
    max-height: 80vh;
    overflow-y: scroll;
  }

  @media(max-width: ${theme.breakpoints.max.sm}){
    padding: 24px 20px 10px;
  }
`;

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export default function Tooltip({ text, alt }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    document.documentElement.style.overflow = 'hidden';
  };

  const handleClose = () => {
    setOpen(false);
    document.documentElement.removeAttribute('style');
  };

  return (
    <ModalOverideStyles>
      <TooltipButton type="button" onClick={handleOpen} aria-label="Open Tooltip">
        <TooltipIcon
          role="img"
          className="info-icon"
          colour={alt ? 'white' : 'green'}
          alt="info icon"
        />
      </TooltipButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <OverlayStyles>
            <ModalContent className={classes.paper}>
              <span id="transition-modal-description">
                <RichText text={text} />
              </span>
              <span className="icon-wrapper">
                <button
                  type="button"
                  className="tooltip-button"
                  onClick={handleClose}
                  aria-label="Close Tooltip"
                >
                  <img className="cross-icon" src={crossIcon} alt="exit icon" />
                </button>
              </span>
            </ModalContent>
          </OverlayStyles>
        </Fade>
      </Modal>
    </ModalOverideStyles>
  );
}

Tooltip.propTypes = {
  text: PropTypes.objectOf(PropTypes.string),
  alt: PropTypes.bool
};
