import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import DropdownMenu from './dropdownMenu.component';
import MenuLink from './menuLink.component';

const MenuItem = styled.li`
  margin: 0px 12px;
  padding: 0px;

  @media(max-width: 1350px) {
    margin: 0;
  }

  ${props => props.menuSectionLastLinkOnRight && css`   
    &:last-child {
      margin-left: auto;
      padding-left: 12px;

      @media(max-width: 1350px) {
        padding-left: 0;
      }
    }
  `}
`;

const MainMenus = ({
  menuData,
  menuSectionLastLinkOnRight,
  currentExpanded,
  setCurrentExpanded
}) => {
  return (
    <>
      {menuData.map((item) => {
        return (
          <MenuItem key={item.id} menuSectionLastLinkOnRight={menuSectionLastLinkOnRight}>
            {item.__typename === 'ContentfulNavigationSection' ? (
              <DropdownMenu menu={item} usedIn="main" currentExpanded={currentExpanded} setCurrentExpanded={setCurrentExpanded} />
            ) : (
              <MenuLink link={item} usedIn="main" />
            )}
          </MenuItem>
        );
      })}
    </>
  );
};
MainMenus.propTypes = {
  menuData: PropTypes.arrayOf(PropTypes.shape({
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.shape({
      linkUrl: PropTypes.string,
      linkText: PropTypes.string,
      openNewTab: PropTypes.bool
    }),
    subnav: PropTypes.shape({
      links: PropTypes.arrayOf(PropTypes.shape({
        linkUrl: PropTypes.string,
        linkText: PropTypes.string,
        openNewTab: PropTypes.bool,
        shortDescription: PropTypes.string,
        linkIcon: PropTypes.shape({
          file: PropTypes.objectOf(PropTypes.string)
        })
      }))
    }),
    linkUrl: PropTypes.string,
    linkText: PropTypes.string,
    openNewTab: PropTypes.bool
  })),
  menuSectionLastLinkOnRight: PropTypes.bool,
  currentExpanded: PropTypes.string,
  setCurrentExpanded: PropTypes.func
};

export default MainMenus;
